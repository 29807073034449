/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, Dispatch, useRef, useMemo, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiArrowRight, FiArrowLeft, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import pagarme from 'pagarme';
import { type } from 'os';
import { Link, useParams } from 'react-router-dom';
import api from '../../../../services/api';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Button';
import SelectSimple from '../../../../components/Forms/SelectSimple';
import * as Yup from 'yup';
import { authTitle, eventName, gateKey } from '../../../../config';
import imgBoleto from '../../../../assets/barcode.png';
import CurrencyInput from '../../../../components/Forms/MaskedCurrencyInput';
import { AnimatedDiv } from '../../styles';
import { Content } from './styles';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';

import Whatsapp from '../../../../components/Whatsapp';

import { ActiveButton, BuyContext } from '../../index';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import {
  ResponseMessage,
  IResponseMessage,
} from '../../../../components/ResponseMessage';

import UpdateUserContainer from '../../../../components/UserUpdateComponent';
import { useAuth } from '../../../../hooks/Auth';
import { useLanguage } from '../../../../hooks/Language';
import { FaBarcode, FaCreditCard, FaInfoCircle, FaQrcode, FaReceipt } from 'react-icons/fa';
import AtualizarCadastroContainer from '../AtualizarCadastro';
import { uuid } from 'uuidv4';
import SignIn from '../../../SignIn';
import { string } from 'yup';
import { useConfiguration } from '../../../../hooks/Configuration';

import CompleteSaleFields from '../CompleteSaleFields';
import CompleteSaleFieldsToSelectiveProcess from '../CompleteSaleFieldsToSelectiveProcess';

import WhatsappFixed from '../../../../components/WhatsappFloat';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import DiscountCoupon from './modules/DiscountCoupon';
import AskSpecialTreatment from '../../../DashboardUserPages/Pages/Home/modules/AskSpecialTreatment';
import IsentionPage from '../../../DashboardUserPages/Pages/IsentionPage';

interface IOptions {
  text?: string;
  label?: string;
  value: string | number;
}

interface IComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
}

interface ICardProps {
  id?: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_number: string;
  card_cvv: string;
  installments?: number;
  document_type: string;
}

interface IInstallment {
  label: string;
  value: string;
}

interface IParams {
  product_hash: string;
}

interface ICoupon {
  label: string;
  value: string;
  price: number;
}

const PaymentMethod: React.FC = () => {
  const { configuration } = useConfiguration();

  const { eventHash, projectHash } = useParams<Record<string, any>>();

  const [buyContext, setBuyContext] = useState<Record<string, any>>({});
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { product_hash } = useParams<IParams>();
  const [couponHash, setCouponHash] = useState<Array<ICoupon>>([]);
  const [defaultValue, setDefaultValue] = useState(0);
  const [amount, setAmount] = useState(0.00);
  const [installmentValue, setInstallmentValue] = useState(1);
  const [quantity, setQuantity] = useState((product_hash === 'apoio' || product_hash === 'apoio-pos') ? 5 : 1);
  const [product, setProduct] = useState<Record<string, any>>({});
  const [closed, setClosed] = useState(false);
  const [productNotFound, setProductNotFound] = useState(false);
  const [paymentType, setPaymentType] = useState('default');
  const [courtesyCode, setCourtesyCode] = useState('');
  const { language } = useLanguage();

  const [category, setCategory] = useState({
    category: '',
    area: '',
    subarea: ''
  });



  const changeSelectiveProcessCategory = ({ column, columnValue }) => {

    if (column === 'category') {

      setCategory({
        category: columnValue,
        area: '',
        subarea: ''
      })


    }
    else if (column === 'area') {
      setCategory(state => ({
        category: state?.category,
        area: columnValue,
        subarea: ''
      }))

    }
    else if (column === 'subarea') {

      setCategory(state => ({
        category: state?.category,
        area: state?.area,
        subarea: columnValue
      }))

    }


  }


  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();

  const [keepOneTicket, setKeepOneTicket] = useState('no');

  const [coupon, setCoupon] = useState('');
  const [couponDiscount, setCouponDiscount] = useState(0);


  const [responseMessage, setResponseMessage] = useState(
    {} as IResponseMessage,
  );

  const { translate } = useLanguage();

  const { user } = useAuth();



  const handleApplyCourtesy = async (data) => {

    if (!data.courtesy_hash) {
      addToast({ title: 'Adicione um código de cortesia', type: 'success' });
      return;
    }
    setLoading(true);
    try {
      const courtesyItem = await api.get(`/check-courtesy/${data.courtesy_hash}/${language}`);
      const newProduct = courtesyItem.data.product;
      setAmount(newProduct?.price * 1);
      setProduct(newProduct);
      setCourtesyCode(data.courtesy_hash);

      const nextContext: Record<string, any> = { ...buyContext, products: [newProduct], amount: newProduct.price };


      nextContext.billing = {};
      nextContext.billing.paymentMethod = 'cortesy';
      setBuyContext(nextContext);
      setLoading(false);
      removeModal('courtesy')
      return;

    }
    catch (err) {

      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao verificar código', type: 'error' });
      setLoading(false);
      return;

    }

  }

  const calculateAmount = (value, quantity) => {
    if (quantity > 1) {
      return (value / (1 - ((quantity - 1) * 0.016)))
    }
    else {
      return value;
    }
  }

  const getQuantities = () => {

    const items: Array<IOptions> = [];

    for (let x = 1; x < 100; x = x + 1) {

      items.push({ value: x, label: x.toString() })



    }

    return items;
  }

  const load = useCallback(async (hash) => {

    if (user) {

      try {
        const userInfo = await api.get(`/verify-user`);

        if (!userInfo) {
          setUpdate(true);
          setLoading(false);
          return;

        }

        setReady(true);

      } catch (err) {
        setUpdate(true);
        setLoading(false);

        addToast({ type: "error", title: translate("Seu cadastro precisa ser atualizado.") });
        return;
      }

      if (product_hash === 'courtesy') {
        setLoading(false);
        return;
      }

      try {

        const response = await api.get(`/product/${product_hash}/${projectHash}`);

        if (!response) {
          setProductNotFound(true);
          return;
        }

        const product = response?.data;

        if (product?.valid_id === 'Não') {
          setClosed(true);
        }

        let newCoupon: Array<ICoupon> = [];



        if (!product) {
          setProductNotFound(true);
          setLoading(false);

          addToast({ type: "error", title: translate("Produto não identificado"), description: translate('Este produto parece não estar mais ativo') });
          return;
        }

        if (product?.any_price === 'yes') {
          removeModal('modalSubscriptionType');
        }

        if (product?.allow_group_sale === 'no') {
          removeModal('modalSubscriptionType');
        }


        setLoading(false);
        setDefaultValue(product.price);
        setAmount(product.price * quantity);
        setProduct(product);

        const nextContext: Record<string, any> = { ...buyContext, products: [product], amount: product.price };

        if (user && user.country && user.country !== 'BR') {
          nextContext.billing = {};
          nextContext.billing.paymentMethod = configuration?.allow_credit_card !== 'no' ? 'credit_card' : 'boleto';
        }

        if (!nextContext?.billing?.paymentMethod) {
          nextContext.billing = {};
          nextContext.billing.paymentMethod = configuration?.allow_credit_card !== 'no' ? 'credit_card' : 'boleto';
        }


        if (product_hash === 'courtesy') {
          nextContext.billing.paymentMethod = 'courtesy';
        }
        setBuyContext(nextContext);



      }
      catch (err) {

        setProductNotFound(true);
        setLoading(false);

        addToast({ type: "error", title: translate("Produto não identificado"), description: translate('Este produto parece não estar mais ativo') });
        return;
      }

    }
  }, [user, buyContext, product_hash, configuration]);

  useEffect(() => {

    if (!product_hash) {
      window.location.href = '/planos';
      return;
    }


    load(product_hash);
    setLoading(false);

  }, [product_hash])


  const handleChangeTypeSubscription = (type) => {
    setPaymentType(type);
    removeModal('modalSubscriptionType');
  }


  useEffect(() => {
    if (quantity && defaultValue) {
      setAmount(quantity * defaultValue);
    }
  }, [quantity, product, defaultValue, installmentValue])

  useEffect(() => {

    if (product_hash === 'courtesy') {

      addModal({
        theme: 'whiteModalMedium',
        key: "courtesy",
        closeButton: false,
        title: '', content: <aside style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start', gap: '15px', padding: '30px 0px' }}>
          <Form className="form"
            ref={formRef}
            onSubmit={handleApplyCourtesy}
            onKeyDown={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}
            onKeyPress={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}

          >

            <h2 style={{ color: '#333', width: '100%', margin: '10px 0px', textAlign: 'center', fontSize: '14px', lineHeight: '18px' }}>
              {translate('Insira seu código da sua cortesia')}
            </h2>

            <Input
              name="courtesy_hash"
              placeholder={translate('Código do cupom')}
            />

            <aside style={{ margin: '10px auto', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Button type="submit">
                {translate('Enviar')}
                <FiArrowRight />
              </Button>
            </aside>

          </Form>
        </aside>

      })

    }
    else if (configuration && user && product?._id && product?.any_price !== 'yes') {
      addModal({
        theme: 'whiteModalMedium',
        key: "modalSubscriptionType",
        closeButton: false,
        title: '', content: <aside style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start', gap: '15px', padding: '30px 0px' }}><h2 style={{ color: '#333' }}>{translate('Escolha o tipo de inscrição')}</h2>
          <button
            className={


              'shadowButtonSelect'
            }
            type="button"
            onClick={() => handleChangeTypeSubscription('default')}
          >

            <p style={{ fontSize: '14px', color: '#333' }}>{translate('Para eu utilizar')}</p>

          </button>

          <button
            className={


              'shadowButtonSelect'
            }
            type="button"
            onClick={() => handleChangeTypeSubscription('gift')}
          >

            <p style={{ fontSize: '14px', color: '#333' }}>{translate('Para outra pessoa')}</p>

          </button>

          <button
            className={


              'shadowButtonSelect'
            }
            type="button"
            onClick={() => handleChangeTypeSubscription('group')}
          >

            <p style={{ fontSize: '14px', color: '#333' }}>{translate('Para 2 ou mais pessoas')}</p>

          </button>
        </aside>

      })
    }

  }, [])


  useEffect(() => {

    if (configuration?._id && user) {

      if (product_hash === 'courtesy') {
        handleChangePaymentMethod('courtesy', false);
      }
      else if (configuration.allow_credit_card !== 'no') {

        handleChangePaymentMethod('credit_card', false);
      }
      else if (user.country === 'BR') {

        handleChangePaymentMethod('boleto', false);
      }

    }

  }, [product, user])


  const scrollToPayment = () => {
    const item = document.getElementById('pagamento');

    item?.scrollIntoView();
  }


  const prepareCategories = (fields) => {

    const items: Array<Record<string, any>> = [];

    fields?.map(item => {

      const index = items.findIndex(i => i.title === item?.type);

      if (index < 0) {

        items.push({ title: item?.type, areas: item?.area ? [{ title: item?.area, subareas: item?.subarea ? [item?.subarea] : [] }] : [] })

      }
      else {




        if (item?.area) {

          const indexArea = items[index]?.areas?.findIndex(a => a.title === item?.area);

          if (indexArea < 0) {
            items[index]?.areas.push({ title: item?.area, subareas: item?.subarea ? [item?.subarea] : [] })
          }
          else {

            if (item?.subarea) {
              items[index]?.areas[indexArea].subareas?.push(item?.subarea);
            }

          }

        }



      }


    })

    return items;


  }

  const checkFieldsSelectiveProcess = (productInfo, data) => {


    const { fields_selective_process } = productInfo;


    const items = prepareCategories(fields_selective_process)


    if (!items) {
      return {};
    }
    if (items?.length < 1) {
      return {};
    }

    if (!data?.category) {
      addToast({ title: 'Selecione uma categoria', type: 'error' });
      return { error: true };
    }



    const categoryIndex = items?.findIndex(i => i?.title === data?.category);

    if (items?.[categoryIndex]?.areas?.length < 1) {
      return { category: data?.category };
    }

    if (!data?.area) {
      addToast({ title: 'Selecione uma Área', type: 'error' });
      return { error: true };
    }


    const areaIndex = items[categoryIndex]?.areas?.findIndex(i => i?.title === data?.area);

    if (!items?.[categoryIndex]?.areas?.[areaIndex]?.subareas || items?.[categoryIndex]?.areas?.[areaIndex]?.subareas?.length < 1) {
      return { category: data?.category, area: data?.area };
    }



    if (!data?.subarea) {
      addToast({ title: 'Selecione uma Subárea', type: 'error' });
      return { error: true };
    }

    return { category: data?.category, area: data?.area, subarea: data?.subarea };

  }


  const handleChangePaymentMethod = useCallback(
    (id, start = true) => {


      setBuyContext({ ...buyContext, billing: { paymentMethod: id } });
      setKeepOneTicket('no')
      //  if (start) { scrollToPayment(); }
    },
    [buyContext, product_hash],
  );


  const installments = useCallback((): Array<IInstallment> => {


    if (buyContext && buyContext.billing && buyContext.billing.paymentMethod === 'boleto') {
      return [{ label: '1x', value: '1' }];
    }

    if (buyContext && buyContext.billing && buyContext.billing.paymentMethod === 'pix') {
      return [{ label: '1x', value: '1' }];
    }

    if (buyContext && buyContext.billing && buyContext.billing.paymentMethod === 'credit_card') {
      const installment: Array<IInstallment> = [];
      for (let x = 1; x <= (product?.any_price === 'yes' ? 1 : 12); x += 1) {

        let currentAmount = amount - (couponDiscount * quantity);

        if (x >= 2) {
          currentAmount = calculateAmount(amount - (couponDiscount * quantity), x);
        }

        if (amount && (currentAmount / x)) {
          installment.push({
            label: `${x}X ${format(
              parseInt(((currentAmount) / x)?.toString(), 10),
            )}${x >= 2 ? translate(' com juros') : ''}`,
            value: x?.toString(),
          });
        }
      }


      return installment;
    }

    else {
      return [{ label: '1x', value: '1' }]
    }
  }, [amount, buyContext, quantity, coupon, couponDiscount, product]);



  const handleFinishCreditCard = useCallback(
    async (card: Record<string, any>) => {
      setLoading(true);
      const cardValidations = await pagarme.validate({ card });

      if (!cardValidations.card.card_number) {
        addModal({
          title: translate('Cartão inválido'),
          content: <>{translate('Verifique o número do seu cartão')}</>,
        });
        setLoading(false);
        return false;
      }


      await pagarme.client
        .connect({ encryption_key: gateKey })
        .then(async (client: Record<string, any>) => {
          const card_hash = await client.security.encrypt(card);

          return card_hash;
        })
        .then(async (card_hash: string) => {
          if (!card_hash) {
            setLoading(false);
            return false;
          }

          if (buyContext?.products?.[0]?.price) {
            buyContext.products[0].price = calculateAmount(amount - (couponDiscount * quantity), card.installments).toFixed(0);
          }

          const context = {
            ...buyContext,
            amount: calculateAmount(amount, card.installments).toFixed(0),
            quantity: quantity,

            billing: {
              paymentMethod: 'credit_card',
              credit_card_hash: card_hash,
              installments: card.installments,
              document_type: card.document_type,
            },
            partner: {},
            fields: {},
            fields_text: ''
          };


          const fieldsValues: Record<string, any> = {};
          let fieldsText = '';

          if ((paymentType !== 'default' && keepOneTicket === 'yes') || paymentType === 'default') {
            if (product.fields && product.fields.length > 0) {

              const shape = {};
              const fieldsKeys: Array<string> = [];


              let x = 1;
              product.fields.map((f: Record<string, any>) => {
                if (f.mandatory === 'yes') {
                  shape[f.field] = Yup.string().required(`${translate(f.field)} ${translate(f.label)}`);
                }
                fieldsKeys.push(f.field);
                fieldsValues[f.field] = card?.[f.field];

                fieldsText = `${fieldsText}${card?.[f.field]}`;
                if (x >= 1 && x < product?.fields?.length) {
                  fieldsText = `${fieldsText} | `;
                }
                x++;

              })

              if (Object.keys(shape).length > 0) {
                const schema = Yup.object().shape(shape)


                try {

                  await schema.validate(card, { abortEarly: false });
                  context.fields = { ...fieldsValues };
                  context.fields_text = fieldsText;
                }
                catch (err) {
                  setLoading(false);
                  const errors = err?.errors && err?.errors?.length > 0 ? err.errors.join(',') : translate('Preencha todos os dados Obrigatórios');

                  return addToast({ type: 'error', title: errors })
                }


              }
            }
          }

          let selectiveProcessFields: Record<string, any> = {};


          selectiveProcessFields = checkFieldsSelectiveProcess(product, category);

          if (selectiveProcessFields?.error) {
            setLoading(false);
            return;
          }


          await api
            .post(`/payment`, {
              ...context,
              keep_one_ticket: paymentType === 'gift' || paymentType === 'group' ? keepOneTicket : 'no',
              payment_type: paymentType === 'gift' ? 'group' : paymentType,
              products: [product],
              language: language,
              fields: fieldsValues,
              fields_text: fieldsText,
              coupon,
              couponDiscount,
              ...selectiveProcessFields
            })
            .then(response => {
              const { data } = response;

              if (data && data.status === 'success') {
                return window.location.href = eventHash ? `/app/${projectHash}/event/${eventHash}/payment/success/${data.hash}` : `/app/${projectHash}/payment/success/${data.hash}`;
              } else {
                addModal({ title: 'Erro', content: data.message, key: '1' });
              }
              setLoading(false);
            })
            .catch(err => {
              addToast({
                title: translate('Erro'),
                description: translate(err?.response?.data?.message || err?.response?.data?.error || translate('Erro ao efetuar pagamento')),
                type: 'error',
              });
              setLoading(false);
              return false;
            });
        });
    },
    [addToast, buyContext, amount, defaultValue, couponHash, language, paymentType, keepOneTicket, coupon,
      couponDiscount, category],
  );

  const askSpecialTreatment = () => {



    addModal({
      theme: 'whiteModalMedium',
      title: 'Solicitação de Atendimento especial',
      key: 'specialTreatment',
      content: <AskSpecialTreatment projectHash={projectHash} />

    })

  }

  const handleFinishCortesy = useCallback(
    async dataForm => {
      setLoading(true);

      let selectiveProcessFields: Record<string, any> = {};


      selectiveProcessFields = checkFieldsSelectiveProcess(product, category);

      if (selectiveProcessFields?.error) {
        setLoading(false);
        return;
      }

      const context = {
        ...buyContext,
        amount: amount,
        quantity: 1,
        coupon_hash: courtesyCode,
        cortesy_hash: courtesyCode,
        billing: {
          paymentMethod: 'cortesy',
          document_type: 'cpf',
        },
        partner: {},
        fields: {},
        fields_text: ''
      };



      if (!courtesyCode) {
        addToast({ title: 'Código do cupom não identificado', type: 'error' });
        setLoading(false);
        return;
      }

      const shape = {


      };

      const fieldsValues: Record<string, any> = {};
      let fieldsText = '';


      if (product.fields && product.fields.length > 0) {

        const fieldsKeys: Array<string> = [];


        let x = 1;
        product.fields.map((f: Record<string, any>) => {
          if (f.mandatory === 'yes') {
            shape[f.field] = Yup.string().required(`${translate(f.field)} ${translate(f.label)}`);
          }
          fieldsKeys.push(f.field);
          fieldsValues[f.field] = dataForm?.[f.field];

          fieldsText = `${fieldsText}${dataForm?.[f.field]}`;
          if (x >= 1 && x < product?.fields?.length) {
            fieldsText = `${fieldsText} | `;
          }
          x++;

        })

        if (Object.keys(shape).length > 0) {
          const schema = Yup.object().shape(shape)


          try {

            await schema.validate(dataForm, { abortEarly: false });
            context.fields = { ...fieldsValues };
            context.fields_text = fieldsText;
          }
          catch (err) {
            setLoading(false);

            const errors = err?.errors && err?.errors?.length > 0 ? err.errors.join(',') : translate('Preencha todos os dados Obrigatórios');

            return addToast({ type: 'error', title: errors })
          }


        }
      }




      await api
        .post(`/payment`, {
          ...context,
          payment_type: 'default',
          products: [product],
          language,
          keepOneTicket: 'no',
          fields: fieldsValues,
          fields_text: fieldsText,
          coupon,
          couponDiscount,
          ...selectiveProcessFields
        })
        .then(response => {
          const { data } = response;
          if (data && data.status === 'success') {
            return window.location.href = eventHash ? `/app/${projectHash}/event/${eventHash}/payment/success/${data.hash}` : `/app/${projectHash}/payment/success/${data.hash}`;
          } else {
            addModal({ title: 'Erro', content: data.message, key: '1' });
          }

          setLoading(false);
        })
        .catch(err => {
          addToast({
            title: translate('Erro'),
            description: translate(err?.response?.data?.message || err?.response?.data?.error || translate('Erro ao efetuar pagamento')),
            type: 'error',
          });
          setLoading(false);
          return false;
        });
    },
    [addToast, buyContext, amount, defaultValue, couponHash, language, paymentType, courtesyCode, coupon,
      couponDiscount, category],
  );



  const handleFinishBoleto = useCallback(
    async (dataForm, type = 'boleto') => {
      setLoading(true);

      let selectiveProcessFields: Record<string, any> = {};


      selectiveProcessFields = checkFieldsSelectiveProcess(product, category);

      if (selectiveProcessFields?.error) {

        setLoading(false);
        return;
      }



      const context = {
        ...buyContext,
        amount: amount,
        quantity: quantity,
        coupon_hash: dataForm?.coupon_hash || 0,
        billing: {
          paymentMethod: type,
          document_type: dataForm.document_type,
        },
        partner: {},
        fields: {},
        fields_text: ''
      };
      const fieldsValues: Record<string, any> = {};
      let fieldsText = '';

      if ((paymentType !== 'default' && keepOneTicket === 'yes') || paymentType === 'default') {

        if (product.fields && product.fields.length > 0) {

          const shape = {};
          const fieldsKeys: Array<string> = [];


          let x = 1;
          product.fields.map((f: Record<string, any>) => {
            if (f.mandatory === 'yes') {
              shape[f.field] = Yup.string().required(`${translate(f.title)}`);
            }
            fieldsKeys.push(f.field);
            fieldsValues[f.field] = dataForm?.[f.field];

            fieldsText = `${fieldsText}${dataForm?.[f.field]}`;
            if (x >= 1 && x < product?.fields?.length) {
              fieldsText = `${fieldsText} | `;
            }
            x++;

          })

          if (Object.keys(shape).length > 0) {
            const schema = Yup.object().shape(shape)


            try {

              await schema.validate(dataForm, { abortEarly: false });
              context.fields = { ...fieldsValues };
              context.fields_text = fieldsText;
            }
            catch (err) {
              setLoading(false);
              const errors = err?.errors && err?.errors?.length > 0 ? err.errors.join(',') : translate('Preencha todos os dados Obrigatórios');

              return addToast({ type: 'error', title: errors })
            }


          }
        }
      }


      await api
        .post(`/payment`, {
          ...context,
          products: [product],
          payment_type: paymentType === 'gift' ? 'group' : paymentType,
          keep_one_ticket: paymentType === 'gift' || paymentType === 'group' ? keepOneTicket : 'no',
          language,
          fields: fieldsValues,
          fields_text: fieldsText,
          coupon,
          couponDiscount,
          ...selectiveProcessFields
        })
        .then(response => {
          const { data } = response;
          if (data && data.status === 'success') {
            return window.location.href = eventHash ? `/app/${projectHash}/event/${eventHash}/payment/success/${data.hash}` : `/app/${projectHash}/payment/success/${data.hash}`;
          } else {
            addModal({ title: 'Erro', content: data.message, key: '1' });
          }

          setLoading(false);
        })
        .catch(err => {
          addToast({
            title: translate('Erro'),
            description: translate(err?.response?.data?.message || err?.response?.data?.error || 'Erro ao efetuar pagamento'),
            type: 'error',
          });
          setLoading(false);
          return false;
        });
    },
    [addToast, buyContext, amount, defaultValue, couponHash, keepOneTicket, paymentType,
      coupon,
      couponDiscount, category],
  );


  if (closed) {
    return <div className="onlineMessage" style={{ display: 'flex', background: '#fff', width: 'auto', maxWidth: '400px', margin: '0px auto', flexDirection: 'column', border: '2px solid #555', borderRadius: '20px', padding: '20px', marginTop: '100px', alignItems: 'center', justifyContent: 'center' }}>

      <h2 className="defaultMarked" style={{ color: '#333' }}>{translate('Inscrição encerrada')}</h2>
      <p style={{ color: '#333', textAlign: 'center', marginTop: '20px' }}>
        {translate(
          'Esta inscrição não está mais disponível',
        )}
      </p><br /><br />
      <Link to={`/app/${projectHash}`}>
        <button className="defaultButton" type="button">
          {' '}
          {translate('Voltar')}
        </button>
      </Link>
    </div>
  }


  if (update) {
    return <AnimatedDiv style={{ flexDirection: 'column', alignItems: 'center' }} visible={true}>
      <AtualizarCadastroContainer />
    </AnimatedDiv>
  }

  if (!user) {
    return <div className="onlineMessage" style={{ display: 'flex', minHeight: 'auto', width: 'auto', maxWidth: '400px', margin: '0px auto', flexDirection: 'column', border: '2px solid #555', borderRadius: '20px', padding: '20px', alignItems: 'center', justifyContent: 'center' }}>

      <p style={{ color: '#555', textAlign: 'center', margin: '20px 0px' }}>
        {translate(
          'Você precisa logar ou se inscrever para finalizar a compra.',
        )}</p>
      <Link to={`/planos/${product_hash}`}><button className="extraButton">{translate('Garantir minha inscrição')}</button></Link>
      <SignIn redirect={eventHash ? `/app/${projectHash}/event/${eventHash}/checkout/${product_hash}` : `/app/${projectHash}/checkout/${product_hash}`} /><br /><br />

    </div>
  }

  if (productNotFound) {
    return <div className="onlineMessage" style={{ display: 'flex', width: 'auto', maxWidth: '400px', margin: '0px auto', flexDirection: 'column', border: '2px solid #555', borderRadius: '20px', padding: '20px', marginTop: '100px', alignItems: 'center', justifyContent: 'center' }}>

      <h2 className="defaultMarked">{translate('Ingresso não encontrado')}</h2>
      <p style={{ color: '#555', textAlign: 'center', marginTop: '20px' }}>
        {translate(
          'Verifique se este ingresso ainda está disponível.',
        )}
      </p><br /><br />
      <Link to="/planos">
        <button className="shadowGreyButton" type="button">
          {' '}
          {translate('Voltar')}
        </button>
      </Link>
    </div>
  }

  return (
    <>
      {loading && <Loader message={`${translate('Carregando')}...`} />}





      {ready && <AnimatedDiv visible={true} style={{ flexDirection: 'column', alignItems: 'center' }}>
        <WhatsappFixed
          message={translate('Suporte Técnico')}
          text={`${translate('Estou finalizando minha inscrição no')} ${authTitle} - ${configuration?.current_event_id_response?.title} - ${product?.title}`}
        />
        {responseMessage.active === true && (
          <ResponseMessage
            active
            type={responseMessage.type}
            title={responseMessage.title}
            description={responseMessage.description}
          />
        )}
        {responseMessage.active !== true && (
          <>
            <Content style={{ width: '100%', maxWidth: '100%', minHeight: '80vh' }}>

              <ActiveButton step={3} />

              <h2 style={{ color: '#333', margin: '10px 0px' }}>{product?.title}</h2>

              {product?.orientation ? <aside style={{ margin: '10px', fontSize: '16px', color: '#333', alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex' }}>{translate(product?.orientation)}</aside> : <></>}

              <aside className='PaymentMethodContainer'>



                <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: ' flex-start', marginTop: '20px' }}>

                  {product?.fields_selective_process && product?.fields_selective_process?.length > 0 ? <> <Form onSubmit={(data) => { }}><CompleteSaleFieldsToSelectiveProcess key="completesalefields" category={category} setCallBack={(item) => changeSelectiveProcessCategory(item)} fields={product.fields_selective_process} /></Form></> : <></>}

                  <aside className='PaymentMethodSelector' >

                    <h2 style={{ fontSize: '14px', padding: '10px', color: '#333' }}>{translate('Selecione a forma de pagamento')}</h2>


                    {configuration?.current_event_id_response?.allow_credit_card !== 'no' && product_hash !== 'courtesy' && product?.allow_credit_card !== 'no' && configuration?.allow_credit_card !== 'no' && <button
                      style={{ display: 'flex', alignItems: 'center', padding: '15px', flexDirection: 'row', margin: '10px', width: '250px', maxWidth: '250px', justifyContent: 'flex-start' }}
                      className={
                        buyContext?.billing?.paymentMethod === 'credit_card'
                          ? 'shadowButton active'
                          : 'shadowButton'
                      }
                      type="button"
                      onClick={() => handleChangePaymentMethod('credit_card')}
                    >
                      <input
                        checked={
                          buyContext?.billing?.paymentMethod === 'credit_card'
                        }
                        name="payment_method"
                        value="credit_card"
                        type="checkbox"
                      />
                      <FaCreditCard size={25} style={{ marginRight: '10px', fontSize: '14px' }} /><p style={{ fontSize: '14px', color: '#333' }}>{translate('Cartão de Crédito')}</p>

                    </button>}
                    {user && user.country === 'BR' && product_hash !== 'courtesy' && configuration?.current_event_id_response?.allow_boleto !== 'no' && configuration?.allow_boleto !== 'no' && product?.allow_boleto !== 'no' && <button style={{ display: 'flex', padding: '10px', alignItems: 'center', width: '250px', maxWidth: '250px', flexDirection: 'row', margin: '10px', justifyContent: 'flex-start' }}
                      className={
                        buyContext?.billing?.paymentMethod === 'boleto'
                          ? 'shadowButton active'
                          : 'shadowButton'
                      }
                      type="button"
                      onClick={() => handleChangePaymentMethod('boleto')}
                    >
                      <input
                        checked={buyContext?.billing?.paymentMethod === 'boleto'}
                        name="payment_method"
                        value="boleto"
                        type="checkbox"
                      />
                      <FaReceipt size={25} style={{ marginRight: '10px', fontSize: '14px' }} /><p style={{ fontSize: '14px', color: '#333' }}>{translate('Boleto')}</p>

                    </button>}

                    {user && user.country === 'BR' && product_hash !== 'courtesy' && configuration?.current_event_id_response?.allow_pix !== 'no' && configuration?.allow_pix !== 'no' && product?.allow_pix !== 'no' && <button style={{ display: 'flex', padding: '10px', alignItems: 'center', width: '250px', maxWidth: '250px', flexDirection: 'row', margin: '10px', justifyContent: 'flex-start' }}
                      className={
                        buyContext?.billing?.paymentMethod === 'pix'
                          ? 'shadowButton active'
                          : 'shadowButton'
                      }
                      type="button"
                      onClick={() => handleChangePaymentMethod('pix')}
                    >
                      <input
                        checked={buyContext?.billing?.paymentMethod === 'pix'}
                        name="payment_method"
                        value="pix"
                        type="checkbox"
                      />
                      <FaQrcode size={25} style={{ marginRight: '10px', fontSize: '14px' }} /><p style={{ fontSize: '14px', color: '#333' }}>{translate('PIX')}</p>

                    </button>}


                    {configuration?.current_event_id_response?.allow_cortesy !== 'no' && product?.allow_cortesy !== 'no' && product_hash === 'courtesy' && paymentType !== 'group' && paymentType !== 'gift' &&
                      <button style={{ display: 'flex', padding: '10px', alignItems: 'center', width: '250px', maxWidth: '250px', flexDirection: 'row', margin: '10px', justifyContent: 'flex-start' }}
                        className={
                          buyContext?.billing?.paymentMethod === 'cortesy'
                            ? 'shadowButton active'
                            : 'shadowButton'
                        }
                        type="button"
                        onClick={() => handleChangePaymentMethod('cortesy')}
                      >
                        <input
                          checked={buyContext?.billing?.paymentMethod === 'cortesy'}
                          name="payment_method"
                          value="cortesy"
                          type="checkbox"
                        />
                        <FaBarcode size={25} style={{ marginRight: '10px', fontSize: '14px' }} /><p style={{ fontSize: '14px', color: '#333' }}>{translate('Tenho uma cortesia')}</p>

                      </button>}


                  </aside>
                  {/* product_hash !== 'courtesy' && product?.any_price !== 'yes' ? <DiscountCoupon product_id={product?._id} setCoupon={setCoupon} coupon={coupon} setCouponDiscount={setCouponDiscount} couponDiscount={couponDiscount} productPrice={product.price} quantity={quantity} /> : <></> */}



                  <aside className='computerOnly' style={{ flexDirection: 'column', maxWidth: '350px', padding: '15px' }}>
                    <h2 style={{ fontSize: '14px', marginTop: '15px', color: '#333', marginBottom: '10px' }}> {translate('Informações do pedido')}</h2>
                    <h2 style={{ fontSize: '14px', marginTop: '15px', color: '#333', marginBottom: '10px' }}>{product?.[`title_${language}`] ? product?.[`title_${language}`] : product?.title}</h2><p style={{ fontSize: '12px', lineHeight: '16px', padding: '0px', color: '#555', textAlign: 'left', width: '100%' }} dangerouslySetInnerHTML={{ __html: product?.[`description_${language}`] ? product?.[`description_${language}`] : product?.description }} />
                  </aside>
                </aside>

                <Content id='pagamento' style={{ display: buyContext?.billing?.paymentMethod ? 'flex' : 'none', paddingTop: '10px' }}>
                  <h2 style={{ fontSize: '14px', padding: '10px', color: '#333' }}>{translate('Preencha os campos abaixo para finalizar')}</h2>





                  {product?.any_price === 'yes' ? <Form onSubmit={() => { }}> <CurrencyInput placeholder='Valor da doação' setCallback={(value) => { setAmount(value.toString().replace(/[^0-9]/g, '')) }} name={'amount'} /><br /></Form> : <></>}

                  <aside>
                    {buyContext?.billing?.paymentMethod === 'credit_card' && (
                      <Form className="form"
                        ref={formRef}
                        initialData={{ installments: installmentValue, quantity: quantity, coupon_hash: "3", document_type: 'cpf' }}
                        onSubmit={handleFinishCreditCard}
                        onKeyUp={event => { const key = event.key || event.keyCode; if (key === 13) { event.stopPropagation(); } }}
                      >


                        {paymentType === 'group' || paymentType === 'gift' ? <CheckboxInput setCallBack={(valueCheckbox: string) => {
                          if (!valueCheckbox) {
                            setKeepOneTicket('no');
                            return;
                          }
                          if (valueCheckbox === 'yes') {
                            setKeepOneTicket('yes')
                          }
                          else {
                            setKeepOneTicket('no');
                          }
                        }} options={[{
                          id: 'Sim', value: 'yes', label: translate('Você deseja utilizar um dos ingressos para o seu cadastro atual?')
                        }]} name="keep_one_ticket" /> : <></>}





                        <SelectSimple index={10} style={{ zIndex: 100000 }}
                          options={[
                            { value: 'cpf', label: translate('Pessoa Física') },
                            {
                              value: 'cnpj',
                              label: translate('Entidade / Pessoa Jurídica'),
                            },
                          ]}
                          name="document_type"
                          formRef={formRef}
                          label={translate('Emitir como')}
                        />

                        <div style={{ marginTop: '10px' }} />
                        {paymentType === 'group' ?
                          <SelectSimple index={11}
                            setCallback={(value) => { setQuantity(parseInt(value)); }}
                            options={[...getQuantities()]}
                            name="quantity"
                            formRef={formRef}
                            label={translate('Quantos ingressos')}
                          /> : <></>}
                        <Input name="card_number" placeholder={translate('Número do Cartão')} />
                        <Input
                          name="card_holder_name"
                          placeholder={translate('Nome no Cartão')}
                        />
                        <MaskedInput
                          mask="99/99"
                          name="card_expiration_date"
                          placeholder={translate('Validade')}
                        />
                        <Input name="card_cvv" placeholder="CVV" />




                        <SelectSimple
                          setCallback={(value) => { setInstallmentValue(value) }}
                          name="installments"
                          label={translate('Parcelas')}
                          options={installments()}
                        />

                        <h2 style={{ margin: '10px 0px', color: '#333' }}>Total: R$ {format(
                          parseInt((calculateAmount(amount - (couponDiscount * quantity), installmentValue))?.toString(), 10),
                        )}</h2>
                        {language !== 'ptBr' ? <p style={{ margin: '10px 0px', color: '#333', fontSize: '12px' }}>{translate('Aprox. ')} $ {
                          parseInt(((calculateAmount(amount - (couponDiscount * quantity), installmentValue) / 100) / 5.20)?.toString(), 10)}</p> : <></>}

                        <aside className='mobileOnly' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                          <h2 style={{ fontSize: '14px', marginTop: '15px', color: '#333', marginBottom: '10px', marginRight: '10px' }}>{translate('Produto')}: {product?.[`title_${language}`] ? product[`title_${language}`] : product.title}</h2> <FaInfoCircle title={translate('+ informações')} style={{ minWidth: '20px', color: 'rgb(0,15,64)' }} size={20} onClick={() => {
                            addModal({ theme: 'whiteModalMedium', title: '', content: <><h2 style={{ fontSize: '18px', marginBottom: '15px', color: '#333' }}>{translate('Benefícios')}</h2><p style={{ fontSize: '16px', lineHeight: '24px', padding: '15px', color: '#555', textAlign: 'left', width: '100%' }} dangerouslySetInnerHTML={{ __html: product?.[`description_${language}`] ? product?.[`description_${language}`] : product?.description }} /></> })
                          }} />
                        </aside>



                        {product.fields && product.fields.length > 0 ? paymentType !== 'default' && keepOneTicket !== 'yes' ? <></> : <> <CompleteSaleFields fields={product.fields} />

                          <br />
                        </> : <></>}
                        <Button type="submit">
                          {translate('Finalizar Compra')}
                          <FiArrowRight />
                        </Button>

                      </Form>
                    )}

                    {buyContext?.billing?.paymentMethod === 'boleto' && (
                      <Form className="form"
                        ref={formRef}
                        onSubmit={(data) => handleFinishBoleto(data, 'boleto')}
                        onKeyDown={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}
                        onKeyPress={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}

                        initialData={{ document_type: 'cpf', quantity: quantity, coupon_hash: "3" }}
                      >
                        {paymentType === 'group' ? <CheckboxInput setCallBack={(valueCheckbox: string) => {
                          if (!valueCheckbox) {
                            setKeepOneTicket('no');
                            return;
                          }
                          if (valueCheckbox === 'yes') {
                            setKeepOneTicket('yes')
                          }
                          else {
                            setKeepOneTicket('no');
                          }
                        }} options={[{
                          id: 'Sim', value: 'yes', label: translate('Você deseja utilizar um dos ingressos para o seu cadastro atual?')
                        }]} name="keep_one_ticket" /> : <></>}


                        {product.fields && product.fields.length > 0 ? paymentType !== 'default' && keepOneTicket !== 'yes' ? <></> : <> <CompleteSaleFields fields={product.fields} />

                          <br />
                        </> : <></>}

                        <SelectSimple index={10} style={{ zIndex: 100000 }}
                          options={[
                            { value: 'cpf', label: translate('Pessoa Física') },
                            {
                              value: 'cnpj',
                              label: translate('Entidade / Pessoa Jurídica'),
                            },
                          ]}
                          name="document_type"
                          formRef={formRef}
                          label={translate('Emitir como')}
                        />




                        {paymentType === 'group' ?
                          <SelectSimple index={11}
                            setCallback={(value) => { setQuantity(parseInt(value)); }}
                            options={[...getQuantities()]}
                            name="quantity"
                            formRef={formRef}
                            label={translate('Quantos ingressos')}
                          /> : <></>}


                        <h2 style={{ margin: '10px 0px', color: '#333' }}>Total: R$ {format(
                          parseInt((amount - (couponDiscount * quantity))?.toString(), 10),
                        )}</h2>

                        {language !== 'ptBr' ? <p style={{ margin: '10px 0px', color: '#333', fontSize: '12px' }}>{translate('Aprox. ')} $ {
                          parseInt(((calculateAmount(amount - (couponDiscount * quantity), installmentValue) / 100) / 5.20)?.toString(), 10)}</p> : <></>}

                        <aside className='mobileOnly' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                          <h2 style={{ fontSize: '14px', marginTop: '15px', color: '#333', marginBottom: '10px', marginRight: '10px' }}>{translate('Produto')}: {product?.[`title_${language}`] ? product[`title_${language}`] : product.title}</h2> <FaInfoCircle title={translate('+ informações')} style={{ minWidth: '20px', color: 'rgb(0,15,64)' }} size={20} onClick={() => {
                            addModal({ theme: 'whiteModalMedium', title: '', content: <><h2 style={{ fontSize: '18px', marginBottom: '15px', color: '#333' }}>{translate('Benefícios')}</h2><p style={{ fontSize: '16px', lineHeight: '24px', padding: '15px', color: '#555', textAlign: 'left', width: '100%' }} dangerouslySetInnerHTML={{ __html: product?.[`description_${language}`] ? product[`description_${language}`] : product.description }} /></> })
                          }} />
                        </aside>
                        <Button type="submit">
                          {translate('Finalizar Compra')}
                          <FiArrowRight />
                        </Button>

                      </Form>
                    )}


                    {buyContext?.billing?.paymentMethod === 'pix' && (
                      <Form className="form"
                        ref={formRef}
                        onSubmit={(data) => handleFinishBoleto(data, 'pix')}
                        onKeyDown={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}
                        onKeyPress={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}

                        initialData={{ document_type: 'cpf', quantity: quantity, coupon_hash: "3" }}
                      >

                        {paymentType === 'group' ? <CheckboxInput setCallBack={(valueCheckbox: string) => {

                          if (!valueCheckbox) {
                            setKeepOneTicket('no');
                            return;
                          }
                          if (valueCheckbox === 'yes') {
                            setKeepOneTicket('yes')
                          }
                          else {
                            setKeepOneTicket('no');
                          }
                        }} options={[{
                          id: 'Sim', value: 'yes', label: translate('Você deseja utilizar um dos ingressos para o seu cadastro atual?')
                        }]} name="keep_one_ticket" /> : <></>}



                        <SelectSimple index={10} style={{ zIndex: 100000 }}
                          options={[
                            { value: 'cpf', label: translate('Pessoa Física') },
                            {
                              value: 'cnpj',
                              label: translate('Entidade / Pessoa Jurídica'),
                            },
                          ]}
                          name="document_type"
                          formRef={formRef}
                          label={translate('Emitir como')}
                        />



                        {paymentType === 'group' ?
                          <SelectSimple index={11}
                            setCallback={(value) => { setQuantity(parseInt(value)); }}
                            options={[...getQuantities()]}
                            name="quantity"
                            formRef={formRef}
                            label={translate('Quantos ingressos')}
                          /> : <></>}



                        <h2 style={{ margin: '10px 0px', color: '#333' }}>Total: R$ {format(
                          parseInt((amount - (couponDiscount * quantity))?.toString(), 10),
                        )}</h2>

                        {language !== 'ptBr' ? <p style={{ margin: '10px 0px', color: '#333', fontSize: '12px' }}>{translate('Aprox. ')} $ {
                          parseInt(((calculateAmount(amount - (couponDiscount * quantity), installmentValue) / 100) / 5.20)?.toString(), 10)}</p> : <></>}
                        <aside className='mobileOnly' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                          <h2 style={{ fontSize: '14px', marginTop: '15px', color: '#333', marginBottom: '10px', marginRight: '10px' }}>{translate('Produto')}: {product?.[`title_${language}`] ? product[`title_${language}`] : product.title}</h2> <FaInfoCircle title={translate('+ informações')} style={{ minWidth: '20px', color: 'rgb(0,15,64)' }} size={20} onClick={() => {
                            addModal({ theme: 'whiteModalMedium', title: '', content: <><h2 style={{ fontSize: '18px', marginBottom: '15px', color: '#333' }}>{translate('Benefícios')}</h2><p style={{ fontSize: '16px', lineHeight: '24px', padding: '15px', color: '#555', textAlign: 'left', width: '100%' }} dangerouslySetInnerHTML={{ __html: product?.[`description_${language}`] ? product[`description_${language}`] : product.description }} /></> })
                          }} />
                        </aside>



                        {product.fields && product.fields.length > 0 ? paymentType !== 'default' && keepOneTicket !== 'yes' ? <></> : <> <CompleteSaleFields fields={product.fields} />
                          <br />
                        </> : <></>}

                        <Button type="submit">
                          {translate('Finalizar Compra')}
                          <FiArrowRight />
                        </Button>

                      </Form>
                    )}


                    {buyContext?.billing?.paymentMethod === 'cortesy' && (
                      <Form className="form"
                        ref={formRef}
                        onSubmit={handleFinishCortesy}
                        onKeyDown={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}
                        onKeyPress={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}

                        initialData={{ quantity: quantity, coupon_hash: "3" }}
                      >
                        {product?.fields && product?.fields?.length > 0 ? <CompleteSaleFields fields={product.fields} /> : <></>}


                        {/*
                        <Input
                          name="cortesy_hash"
                          placeholder={translate('Código de cortesia')}
                        />


                       
                      <h2 style={{ margin: '10px 0px', color: '#333' }}>{product?.title?.indexOf('Cortesia') >= 0 ? translate('Cortesia') : `Total: R$ ${format(
                          parseInt((amount)?.toString(), 10),
                      )}`}</h2> 
                        {language !== 'ptBr' ? <p style={{ margin: '10px 0px', color: '#333', fontSize: '12px' }}>{translate('Aprox. ')} $ {
                          parseInt(((calculateAmount(amount - (couponDiscount * quantity), installmentValue) / 100) / 5.20)?.toString(), 10)}</p> : <></>}*/}
                        <aside className='mobileOnly' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                          <h2 style={{ fontSize: '14px', marginTop: '15px', color: '#333', marginBottom: '10px', marginRight: '10px' }}>{translate('Produto')}: {product?.[`title_${language}`] ? product[`title_${language}`] : product.title}</h2> <FaInfoCircle title={translate('+ informações')} style={{ minWidth: '20px', color: 'rgb(0,15,64)' }} size={20} onClick={() => {
                            addModal({ theme: 'whiteModalMedium', title: '', content: <><h2 style={{ fontSize: '18px', marginBottom: '15px', color: '#333' }}>{translate('Benefícios')}</h2><p style={{ fontSize: '16px', lineHeight: '24px', padding: '15px', color: '#555', textAlign: 'left', width: '100%' }} dangerouslySetInnerHTML={{ __html: product?.[`description_${language}`] ? product[`description_${language}`] : product.description }} /></> })
                          }} />
                        </aside>
                        {product.fields && product.fields.length > 0 ? <> <CompleteSaleFields fields={product.fields} />
                          <br />
                        </> : <></>}

                        <Button type="submit">
                          {translate('Finalizar Compra')}
                          <FiArrowRight />
                        </Button>

                      </Form>
                    )}
                  </aside>


                </Content>
              </aside>
            </Content>

          </>
        )
        }
        <p style={{ color: '#fff', marginTop: '30px', background: '#3f507e', padding: '5px', borderRadius: '15px', cursor: 'pointer' }}

          onClick={() =>
            addModal({
              title: '',
              content: <UpdateUserContainer />,
              key: 'userUpdateForm',
              theme: 'whiteModal'
            })}
        >
          {translate('Clique aqui caso precise revisar seu cadastro')}
        </p>
      </AnimatedDiv >}
    </>
  );
};

export default PaymentMethod;

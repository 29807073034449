import React, { useEffect, useState } from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, urlWebsite } from '../../../../../../config';
import { FaCheckCircle, FaMoneyBill } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { useModal } from '../../../../../../hooks/Modal';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { string } from 'yup';
import CheckboxInput from '../../../../core/components/Forms/CheckboxInput';
import { Form } from '@unform/web';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import TextareaInput from '../../../../core/components/Forms/TextareaInput';
import { ContentAvaliation } from './styles';
import { useSearch } from '../../../../../../hooks/Search';
import { rgb } from 'polished';
import Loader from '../../../../core/components/Loader';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IProps {
  content: Record<string, any>;
  values: Record<string, any>;
  avaliation: Record<string, any>;
  setAvaliation: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  currentAvaliationIndex: number;
  setCurrentAvaliationIndex: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  globalCount: number;
}

interface IPropsList {
  content: Record<string, any>;
  lineData: Record<string, any>;
}

interface IOption {
  id: string;
  value: string;
  label: string;
  sub_title?: string;
}

interface IPropsRecurse {
  lineData: Record<string, any>;
}


const VisualizeCurriculum: React.FC<IProps> = ({ content, values, index, globalCount, avaliation, setAvaliation, currentAvaliationIndex, setCurrentAvaliationIndex }) => {


  const [showNota, setShowNota] = useState(false);
  const [hugeImage, setHugeImage] = useState('')


  const optionsList = content?.categories ? [...content?.categories] : [];


  const calculateSub = (data, sub, index = 0, url) => {





    const points = parseFloat(content?.categories?.[index]?.points);
    let value = 0;
    let currentPoints = 0;

    /* key === url */
    Object.keys(data.images).map(key => {



      currentPoints = currentPoints + parseFloat(data.images[key]);

      if (currentPoints > points) {
        currentPoints = points;
      }

    });





    return currentPoints > points ? points.toFixed(2) : currentPoints.toFixed(2);


  }


  const calculate = (data, sub, index = 0) => {



    const points = parseFloat(content.points);
    let value = 0;



    Object.keys(data.avaliations).map(key => {

      const content = data.avaliations[key]?.avaliations || {};
      const points = parseFloat(data.avaliations[key]?.points);



      let currentPoints = 0;

      Object.keys(content).map(k => {
        const imgValue = content[k];


        currentPoints = currentPoints + parseFloat(imgValue);

        if (currentPoints > points) {
          currentPoints = points;
        }

      });

      value = value + currentPoints;


    })


    return value > points ? points.toFixed(2) : value.toFixed(2);


  }

  const updateAvaliation = (value) => {




    const getAvaliation = { ...avaliation };

    if (!getAvaliation[content.hash]) {
      getAvaliation[content.hash] = {
        points: content.points,
        current_value: 0,
        avaliations: {},
        subcategories: []
      }
    }



    Object.keys(getAvaliation[content.hash].avaliations).map(k => {

      if (getAvaliation?.[content?.hash]?.avaliations[k]?.avaliations?.[value?.url]) {
        delete getAvaliation[content.hash].avaliations[k]?.avaliations?.[value?.url];

        if (getAvaliation[content.hash].avaliations[k]?.avaliations.length < 1) {
          delete getAvaliation[content.hash].avaliations[k];
        }


      }

    })


    if (!getAvaliation[content.hash].avaliations?.[value.title]) {
      getAvaliation[content.hash].avaliations[value.title] = {
        points: value.points, avaliations: {
          [value?.url]: value.value
        }
      }
    }
    else {
      getAvaliation[content.hash].avaliations[value.title].avaliations[value?.url] = value.value
    }


    const calculateValue = calculate(getAvaliation[content.hash], false);

    getAvaliation[content.hash].current_value = calculateValue;


    setAvaliation({ ...getAvaliation })


  }



  const updateAvaliationSub = (value, index) => {




    /* Qual a subcategoria */

    /* Qual o valor da opção selecionada */

    /* Gravar na avaliação */

    /* Atualizar o valor parcial */


    const getAvaliation = { ...avaliation };

    if (!getAvaliation[content.hash]) {
      getAvaliation[content.hash] = {
        points: content.points,
        current_value: 0,
        avaliations: {},
        subcategories: []
      }
    }



    Object.keys(getAvaliation[content.hash].avaliations).map(k => {

      if (getAvaliation?.[content?.hash]?.avaliations[k]?.avaliations?.[value?.url]) {
        delete getAvaliation[content.hash].avaliations[k]?.avaliations?.[value?.url];

        if (getAvaliation[content.hash].avaliations[k]?.avaliations.length < 1) {
          delete getAvaliation[content.hash].avaliations[k];
        }


      }

    })


    if (!getAvaliation[content.hash].avaliations?.[value.title]) {
      getAvaliation[content.hash].avaliations[value.title] = {
        points: value.points, avaliations: {
          [value?.url]: value.value
        }
      }
    }
    else {
      getAvaliation[content.hash].avaliations[value.title].avaliations[value?.url] = value.value
    }


    if (!getAvaliation[content.hash].sub_values) {
      getAvaliation[content.hash].sub_values = [];
    }



    if (!getAvaliation[content.hash].sub_values[index]) {
      getAvaliation[content.hash].sub_values[index] = {

        points: content?.categories?.[index]?.points,
        value: 0,
        images: {
          [value.url]: value.value
        }
      };
    }
    else {
      getAvaliation[content.hash].sub_values[index].images[value.url] = value.value
    }


    getAvaliation[content.hash].sub_values[index].value = calculateSub(getAvaliation[content.hash].sub_values[index], true, index, value.url);





    let finalPoint = 0;

    getAvaliation[content.hash].sub_values.map(p => {
      finalPoint = finalPoint + parseFloat(p?.value || '0')
    })



    getAvaliation[content.hash].current_value = content.points <= finalPoint ? content.points : finalPoint;


    setAvaliation({ ...getAvaliation })


  }


  if (content.show_subcategories === 'no') {

    return <ContentAvaliation style={{ color: '#333', display: 'flex' }} >
      <div className='contentAvaliationScore'>
        <h2>{content.title} </h2>{showNota ? <nav style={{ minWidth: '100px' }}>Nota : {avaliation?.[content?.hash]?.current_value ? parseFloat(avaliation?.[content?.hash]?.current_value).toFixed(2) : "0"}</nav> : <></>}

      </div>
      <div className='separator' />

      {values?.uploads?.length < 1 ? <h2 style={{ color: '#777', margin: '25px 0px' }}>Nenhum documento adicionado</h2> : <></>}

      {values?.uploads?.map((image, imageIndex) => {

        const options: Array<IOption> = [];

        {
          content?.categories?.map((av, index) => {
            options.push({ value: index, id: index, label: av.title });
          })
        }

        return <div style={{ background: '#eee', padding: '0px 15px', margin: '10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '15px', borderRadius: '10px' }}>
          <p className='title'>{imageIndex + 1} </p>
          {image?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`/apoio/pdf.png`} style={{ cursor: 'pointer', width: '70px', minWidth: '70px', padding: '5px' }} /> </a> : <img onClick={() => setHugeImage(`${urlWebsite}/${image.url}`)} key={image.url} src={`${urlWebsite}/${image.url}`} style={{ width: '100%', cursor: 'pointer', maxWidth: '200px' }} />}

          {showNota ? <nav style={{ minWidth: '200px', background: '#ddd', padding: '10px' }}> Avaliação:

            {options.map(opt => {



              return avaliation?.[content?.hash]?.avaliations?.[opt.label]?.avaliations?.[image.url] ? <p style={{ color: '#333', fontSize: '12px' }}>{opt.label}<br /><br />Pontuação do doc.: {avaliation[content.hash].avaliations[opt.label].avaliations[image.url]} </p> : <></>;
            })}
          </nav> : <></>}
        </div>
      })}

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>



      </div>
      <div className='hugeImage' style={{ display: hugeImage ? 'flex' : 'none' }}>
        <button className='nextAvaliation' onClick={() => setHugeImage('')}>Fechar</button>
        <div>


          <img src={hugeImage} style={{ width: '100%' }} />
        </div>

      </div>
    </ContentAvaliation>
  }



  return <ContentAvaliation style={{ color: '#333', display: 'flex' }} >
    <div className='contentAvaliationScore'>
      <h2>{content.title} </h2>{showNota ? <nav style={{ minWidth: '100px' }}>Nota : {avaliation?.[content?.hash]?.current_value || "0"}</nav> : <></>}

    </div>
    <div className='separator' />

    {content.categories.map((category, categoryIndex) => {


      const key = category.hash;
      return <>
        {values?.[key]?.length < 1 ? <h2 style={{ color: '#777', margin: '25px 0px' }}>Nenhum documento adicionado</h2> : <></>}

        {values?.[key]?.map((image, imgIndex) => {

          const options: Array<IOption> = [];


          category?.subcategories?.map((av, index) => {
            options.push({ value: index, id: index, label: av.title });
          })






          return <>
            {imgIndex < 1 ? <p style={{ padding: '10px', fontSize: '12px' }}><strong> Subcategoria:</strong><br />{category.title} </p> : <></>}
            <div style={{ background: '#eee', padding: '0px 15px', margin: '10px 0px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '15px', borderRadius: '10px' }}>


              <p className='title'>{imgIndex + 1} </p>

              {image?.url?.toLowerCase()?.indexOf('.pdf') >= 0 ? <a href={`${urlWebsite}/${image.url}`} download><img key={image.url} src={`/apoio/pdf.png`} style={{ cursor: 'pointer', width: '70px', minWidth: '70px' }} /></a> : <img onClick={() => setHugeImage(`${urlWebsite}/${image.url}`)} key={image.url} src={`${urlWebsite}/${image.url}`} style={{ cursor: 'pointer', minWidth: '50px', width: '50px' }} />}




              {showNota ? <nav style={{ minWidth: '200px', background: '#ddd', padding: '10px' }}><h3>Avaliação:</h3>
                {options.map(opt => {

                  return avaliation?.[content?.hash]?.avaliations?.[opt.label]?.avaliations?.[image.url] ? <p style={{ color: '#333', fontSize: '12px' }}>{opt.label}<br /><br />Pontuação do doc.:  {avaliation?.[content.hash]?.sub_values?.[categoryIndex].images?.[image.url]} </p> : <></>

                })}
              </nav> : <></>}
            </div>

          </>
        })}

      </>
    })}





    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>




    </div>
    <div className='hugeImage' style={{ display: hugeImage ? 'flex' : 'none' }}>
      <button className='nextAvaliation' onClick={() => setHugeImage('')}>Fechar</button>
      <div>


        <img src={hugeImage} style={{ width: '100%', }} />
      </div>

    </div>
  </ContentAvaliation >

}


const VisualizeCurriculumList: React.FC<IPropsList> = ({ content, lineData }) => {
  const [avaliation, setAvaliation] = useState<Record<string, any>>({});
  const [showNota, setShowNota] = useState(false);
  const [currentAvaliationIndex, setCurrentAvaliationIndex] = useState(0);
  const { addToast } = useToast();
  const { removeModal } = useModal();
  const { reloadSearchAll } = useSearch();



  const globalValue = Object.keys(avaliation).reduce((prev, key) => {


    prev = prev + parseFloat(avaliation?.[key]?.current_value);


    return prev;

  }, 0)

  const finishAvaliation = async () => {

    try {
      const response = await api.post(`/finish_curriculum_avaliation/${lineData._id}`, {
        curriculum_points: parseFloat(globalValue.toFixed(2)),
        avaliation: avaliation,
      });

      addToast({ title: 'Atualizado com sucesso' });
      removeModal('curriculum_avaliation');
      reloadSearchAll();
      return;
    }
    catch (err) {
      console.log(err);
      addToast({ title: err?.response?.data?.message || 'Erro ao salvar avaliação' })
    }

  }

  const fields: Array<JSX.Element> = [];

  content.map((item, index) => {

    const items = lineData?.curriculum_recurse_documents?.[item.hash] || [];



    fields.push(<VisualizeCurriculum key={item.hash} globalCount={content.length} index={index} setCurrentAvaliationIndex={setCurrentAvaliationIndex} currentAvaliationIndex={currentAvaliationIndex} setAvaliation={setAvaliation} avaliation={avaliation} content={item} values={items} />);

  })

  return <>
    <div style={{ width: '100%', maxWidth: '500px', margin: '10px auto' }}>
      {showNota ? <h2 style={{ background: '#41517E', color: '#fff', textAlign: 'left', borderRadius: '10px', width: '100%', padding: '15px', margin: '0px auto' }}>Nota Global: {
        globalValue.toFixed(2)
      }</h2> : <></>}
    </div>

    {fields}


  </>
}

const Recurse: React.FC<IPropsRecurse> = ({ lineData }) => {

  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { configuration } = useConfiguration();
  const { reloadSearchAll } = useSearch();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(lineData?.category?.toUpperCase() === 'PRÉ-REQUISITOS' ? configuration.curriculum_configuration_pre_requisitos ? [...configuration.curriculum_configuration_pre_requisitos] : [] : configuration.curriculum_configuration ? [...configuration.curriculum_configuration] : []);



  const sendRecurse = async (data) => {



    if (!data?.curriculum_recurse_avaliation_status) {
      addToast({ title: 'Selecione se o recurso foi aprovado ou negado', type: 'error' });
      return;
    }

    if (data?.curriculum_recurse_avaliation_status === 'no' && !data?.curriculum_recurse_avaliation_justification) {
      addToast({ title: 'Em caso de negativa para o recurso, é necessário adicionar uma justificativa.', type: 'error' });
      return;
    }

    setLoading(true);

    try {
      const response = await api.post(`/send_curriculum_recurse_avaliation/${lineData?._id?.toString() || ''}`, {

        ...data

      });
      setLoading(false)
      addToast({ title: 'Recurso avaliado com sucesso', type: 'success' });
      removeModal('curriculum_recurse');
      reloadSearchAll()

      return;


    }

    catch (err) {
      setLoading(false)
      addToast({ type: 'error', title: err?.response?.data?.message || 'Erro ao avaliar recurso' });

      return;
    }



  }

  return <>
    {loading ? <Loader message='Carregando' /> : <></>}
    <h2 style={{ width: '100%', color: '#333', padding: '10px', maxWidth: '600px', margin: '10px auto' }}>Instruções</h2>
    <p style={{ maxWidth: '600px', margin: '10px auto', marginBottom: '20px', width: '100%', color: '#333', padding: '10px', border: '1px solid #ddd', borderRadius: '10px' }}>Prezado avaliador, abaixo você conseguirá visualizar a solicitação de recurso do participante.<br /><br />

      Esta avaliação é composta de 3 tópicos: <strong>Justificativa</strong>, <strong>Documentos anexados no recurso</strong>{` e `}

      <strong>Avaliação</strong><br /><br />

      <b>Após realizar a avaliação do recurso</b>, caso aprovado, os documentos anexados serão adicionados aos documentos do participante e será necessário <b>refazer a avaliação da análise currícular do participante</b>.
      <br /><br />
      Os documentos adicionados no recurso estarão sempre no final da categoria / subcategoria ao qual se inserem.
      <br /><br />
      <strong>Boa avaliação de recurso</strong>

    </p>


    <h2 style={{ width: '100%', color: '#333', padding: '10px', maxWidth: '600px', margin: '10px auto' }}>Justificativa</h2>
    <p style={{ maxWidth: '600px', margin: '10px auto', marginBottom: '20px', width: '100%', color: '#333', padding: '10px', border: '1px solid #ddd', borderRadius: '10px' }}>{lineData?.curriculum_recurse_justification}</p>

    <h2 style={{ width: '100%', color: '#333', padding: '10px', maxWidth: '600px', margin: '10px auto' }}>Documentos anexados no recurso</h2>
    <VisualizeCurriculumList content={content} lineData={lineData} />

    <h2 style={{ width: '100%', color: '#333', padding: '10px', maxWidth: '600px', margin: '10px auto' }}>Avaliação</h2>

    {lineData?.curriculum_recurse_avaliation_finished !== 'yes' ? <Form onSubmit={sendRecurse} style={{ maxWidth: '600px', width: '100%', margin: '10px auto', background: '#ddd', padding: '25px', borderRadius: '10px' }}>
      <p style={{ fontSize: '12px', color: '#333', width: '100%', textAlign: 'left' }}>O Recurso foi aprovado?</p>
      <SelectSimple label="" name={'curriculum_recurse_avaliation_status'} options={[
        { label: 'Aprovado', value: 'yes' },
        { label: 'Negado', value: 'no' },
      ]} />
      <p style={{ fontSize: '12px', color: '#333', width: '100%', textAlign: 'left', marginTop: '15px' }}>Justificativa</p>
      <TextareaInput placeholder="" name="curriculum_recurse_avaliation_justification" />


      <button style={{ marginTop: '15px' }} className='defaultButton'>Finalizar</button>
    </Form> : <><h2 style={{ color: '#fff', background: '#333', padding: '20px', margin: '20px auto', width: '100%', maxWidth: '600px', textAlign: 'center' }}>Este recurso já foi avaliado</h2>
      <p style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: lineData?.curriculum_recurse_avaliation_justification }} />

    </>}
  </>
}


export function useAvaliateCurriculum({ lineData }) {

  const { projectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const { configuration } = useConfiguration();
  const { reloadSearchAll } = useSearch();





  const gerarRelatorioFinanceiro = async () => {





    addModal({
      title: 'Visualizar Currículo',
      content: <>

        <Recurse lineData={lineData} />

      </>,
      theme: 'whiteModal',
      key: 'curriculum_recurse',
      closeButton: false
    })


  }

  const moduleUpdate = (): JSX.Element => (
    projectHash && lineData.curriculum_recurse_status === 'yes' ? <button className='defaultButtonMini' style={{ background: lineData?.curriculum_recurse_avaliation_finished !== 'yes' ? 'orange' : lineData.curriculum_recurse_avaliation_status === 'yes' ? 'rgb(0,150,0)' : 'rgb(250,50,0)', color: '#fff' }} type="button" onClick={() => lineData?.curriculum_recurse_avaliation_finished !== 'yess' ? gerarRelatorioFinanceiro() : false}>
      {lineData?.curriculum_recurse_avaliation_finished !== 'yes' ? 'Avaliar Recurso' : lineData?.curriculum_recurse_avaliation_status === 'yes' ? 'Recurso aprovado' : 'Recurso negado'}
    </button> : <></>
  );

  return moduleUpdate();
}

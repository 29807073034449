import React, { useEffect, useState } from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';
import { dateLanguage, dateSimple, simpleDateUS } from '../../../../utils/date';
import { addHours } from 'date-fns';
import { Form } from '@unform/web';
import DatePicker from '../../core/components/Forms/DatePicker';
import downloadit, { downloaditPost } from '../../../../utils/download';
import { apiUrl } from '../../../../config';
import { useToast } from '../../../../hooks/Toast';
import { date } from 'yup';
import Loader from '../../core/components/Loader';
import { FaChartBar, FaChartPie, FaChevronCircleDown, FaChevronCircleUp, FaChevronRight, FaEdit, FaFileCsv, FaFileExcel, FaFilePdf, FaInfo, FaMoneyBill, FaSpinner } from 'react-icons/fa';
import ChartBar from '../../core/components/Charts/Bar';
import PieChart from '../../core/components/Charts/Pie';
import { useConfiguration } from '../../../../hooks/Configuration';
import Table from '../../../../styles/components/table';
import { FinanceReportByProjectModule } from '../Platforms/Modules/CheckFinance';


interface IGroup {
  search: Array<Record<string, any>>;
  data: Array<Record<string, any>>;
  where?: Record<string, any>;
  prefix?: string;
}


const getGroup = ({ data, search, where = {}, prefix = '' }: IGroup) => {

  const keys = Object.keys(where);


  let newData = data;
  /* Make a filter on data */
  keys.map(key => {

    newData = newData.filter(d => d[key] === where[key]);
  })

  const report: Array<IResume> = [];

  /* Searchable without ignored fields */
  search.map(s => {

    report.push({
      title: `${prefix}${s.label}`,
      column: s.ref, /* column name */
      data: []
    })


  })

  newData.map(filterDataLine => {


    report.map((item, index) => {

      const columnName = filterDataLine[item.column] ? filterDataLine[item.column].trim().toUpperCase() : 'Não informado';

      const indexSubItem = report[index].data.findIndex(elem => elem?.title === columnName);



      if (indexSubItem >= 0) {
        report[index].data[indexSubItem].count = report[index].data[indexSubItem].count + 1;
        report[index].data[indexSubItem].value = report[index].data[indexSubItem]?.value ? (report[index]?.data[indexSubItem]?.value || 0) + 1 : 1;
      }
      else {
        report[index].data.push({ column: item?.column, id: columnName, title: columnName, label: columnName, value: 1, count: 1 })
      }



    });


  })





  return report;

}


interface IDetails {
  title: string;
  column?: string;
  id?: string;
  count: number;
  label?: string;
  value?: number;
}


interface IResume {
  data: Array<IDetails>;
  title: string;
  column: string;
  setCallback?: Function;
  active?: boolean;
}

interface IResumeList {
  data: Array<IResume>
}

const ReadResumeList: React.FC<IResumeList> = ({ data }) => {



  const [currentResume, setCurrentResume] = useState('');



  return <>
    {data?.length > 0 ? data.map(resume => <ReadResume column={resume.column} title={resume.title} data={resume.data} active={resume.title === currentResume ? true : false} setCallback={(value) => setCurrentResume(value)} />) : <></>}
  </>

}

const ReadResume: React.FC<IResume> = ({ title = '', data, active = false, setCallback = (value) => { } }) => {

  const [type, setType] = useState('details');

  const convertToArrayAndExport = ({ data, type = 'xlsx', title }) => {
    data.sort((a, b) => a?.count < b?.count ? 1 : a?.count > b?.count ? -1 : 0);
    const content = [[title, 'Quantidade']];

    data.map(item => {
      content.push([item.title, item.count || 0])
    })



    return downloaditPost({ url: `${apiUrl}/converter/${type}`, data: content, type });

  }

  return <>

    <div className='list-template-dark'>
      <div className='list-template-date'>{title}</div>
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <div className='list-template-quantity' style={{ width: '70px' }}>{data.length || '0'}</div>


        <div className='list-template-quantity' style={{ cursor: 'pointer' }}>
          <FaInfo onClick={() => { setCallback(title); setType('details') }} size={20} />
          <FaFileExcel size={20} onClick={() => convertToArrayAndExport({ title, data, type: 'xlsx' })} />
          <FaFileCsv size={20} onClick={() => convertToArrayAndExport({ title, data, type: 'csv' })} />
          {/*<FaChartBar onClick={() =>   { setType('chartBar'); setCallback(title)}} size={20}/> */}
          <FaChartPie onClick={() => { setType('chartPie'); setCallback(title) }} size={20} />{active ? <FaChevronCircleUp onClick={() => setCallback(title)} size={20} /> : <FaChevronCircleDown onClick={() => setCallback(title)} size={20} />}</div>
      </div>

    </div>
    <div style={{ display: active ? 'flex' : 'none', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <ReadDetails type={type} data={data} />

    </div>

  </>



}



interface IPropsReadDetails {
  data: Array<IDetails>;
  type: string;
}


const ReadDetails: React.FC<IPropsReadDetails> = ({ data, type = 'details' }) => {

  /* type can be "Details" or "Chart" */


  const [contentList, setContentList] = useState<Array<IDetails>>([]);
  const [content, setContent] = useState<Array<JSX.Element>>([]);

  const getContent = {
    details: (dataDetails: Array<IDetails>) => {
      return <table style={{ width: '100%' }}>
        {dataDetails.map((d, index) => {

          return <tr key={`${d.title}-${d.count}-${index}`} className='list-template'>
            <td className='list-template-date'>{d.title}</td>
            <td className='list-template-quantity'>{d.count || '0'}</td>
          </tr>

        })}
      </table>;




    },
    chartBar: (dataDetails: Array<IDetails>) => {
      return <div style={{ width: '100%', overflowX: 'scroll' }}>  <div style={{ width: '100%', minWidth: `${dataDetails.length * 100}px`, height: '50vh' }}><ChartBar indexBy="title" headers={['count']} data={dataDetails} /></div></div>

    },
    chartPie: (dataDetails: Array<IDetails>) => {
      return <div style={{ width: '100%', overflowX: 'scroll' }}>  <div style={{ width: '100%', height: '50vh', padding: '20px 0px', overflowY: 'auto' }}><PieChart indexBy="title" headers={['count']} data={dataDetails} /></div></div>

    }

  }


  useEffect(() => {

    const newList = [...data];

    newList.sort((a, b) => {
      if (a?.count < b?.count) { return 1; }
      else if (a?.count > b?.count) { return -1; }
      else { return 0; }

    })

    setContentList(newList);

  }, [data]);




  return getContent?.[type] ? getContent?.[type](contentList) : <> - </>;



}



interface IParams {
  projectHash: string;
  eventHash: string;
}

interface IProps {
  hash?: string;
}

const Resumo: React.FC<IProps> = ({ hash = '' }) => {

  const [project, setProject] = useState<Record<string, any>>({});
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [currentReport, setCurrentReport] = useState('');

  const { projectHash = hash, eventHash } = useParams<IParams>();

  const [blockDetail, setBlockDetail] = useState<Array<Record<string, any>>>([]);

  const [report, setReport] = useState<Array<IResume>>([]);
  const [showReport, setShowReport] = useState(1);

  const load = async () => {
    setLoading(true);
    const projectReport = await api.get(!eventHash ? `/report-project/${projectHash}` : `/report-project/${projectHash}/${eventHash}`);

    setProject(projectReport.data);
    setLoading(false);
  }




  const readList = (list) => {

    const items: Array<JSX.Element> = [];

    const dateList: Array<Record<string, any>> = [];
    if (list && list.length > 0) {





      list.map(l => {
        dateList.push({ hora: l.hora, usuarios: l.usuarios });

      })







    }

    return <div style={{ width: '100%' }}>  <div style={{ width: '100%', height: '50vh' }}> <ChartBar headers={['hora', 'usuarios']} data={dateList} indexBy="hora" /></div></div>
      ;

  }

  const readListFields = (list) => {

    const items: Array<JSX.Element> = [];

    let paid = 0;
    let waiting = 0;


    const dateList: Array<Record<string, any>> = []


    list?.map(item => {
      paid = item.paid + paid;
      waiting = item.waiting + waiting;

    })
    /* 
        if (list?.[0]?.fields?.length < 1 || list?.[0]?.fields?.[0]?.title) {
    
          return <>
    
    
            {list?.map(item => {
              return <><tr className='highlight' key={`item-${item.title}`}  >
                <th >{item.title}</th>
                <th>Pagos</th>
                <th>Aguardando</th>
              </tr>
                {item?.fields?.map(itemField => {
    
                  return <tr key={`item-${item.title}-${itemField.title}`}  >
                    <td >{itemField.title}</td>
                    <td>{itemField.paid}</td>
                    <td>{itemField.waiting}</td>
                  </tr>
    
    
                })}
    
    
                <tr >
                  <td >Total</td>
                  <td>{item.paid}</td>
                  <td>{item.waiting}</td>
                </tr>
    
              </>
    
    
    
            })}
    
    
          </>;
        }
    
    */

    return <>


      {list?.map(item => {

        item?.fields.sort((a, b) => {

          let itemA = `${a?.type}-${a?.area}-${a?.subarea}`;
          let itemB = `${b?.type}-${b?.area}-${b?.subarea}`;

          return itemA > itemB ? 1 : itemA < itemB ? -1 : 0;

        })

        return <> <tr key={`item-${item.title}`}  >
          <th colSpan={4} >{item.title}</th>
          <th>Pagos</th>
          <th>Aguardando</th>

        </tr>
          <tr className='highlight' key={`item-${item.title}-area-header`}  >
            <th   >Categoria</th>
            <th>Área</th>
            <th>Subárea</th>
            <th>Outros Campos</th>
            <th>Pagos</th>
            <th>Aguardando</th>
          </tr>
          {
            item?.fields?.map(fieldItem => {

              return <tr key={`item-${item.title}-${fieldItem?.type}-${fieldItem?.area}-${fieldItem?.subarea}`}  >
                <td >{fieldItem.type}</td>
                <td >{fieldItem.area}</td>
                <td >{fieldItem.subarea}</td>
                <td >{fieldItem.title}</td>
                <td>{fieldItem.paid}</td>
                <td>{fieldItem.waiting}</td>

              </tr>


            })
          }




          <tr >
            <td colSpan={3} >Total</td>
            <td>{item.paid}</td>
            <td>{item.waiting}</td>
          </tr>  </>
      })}


    </>;




  }

  useEffect(() => {

    load();

  }, [projectHash])

  const reportProject = async (data) => {
    if (data.date) {
      data.date = simpleDateUS(data.date);
    }
    setLoading(true);
    addToast({ title: 'Preparando download', type: 'success' })
    await downloadit({ url: !eventHash ? `${apiUrl}/report-project-list/${projectHash}?type=xlsx&date=${encodeURI(data.date ? data.date : '')}` : `${apiUrl}/report-project-list/${projectHash}/${eventHash}?type=xlsx&date=${encodeURI(data.date ? data.date : '')}` });

    setLoading(false);
  }


  const getReport = async (type) => {
    setLoading(true);
    addToast({ title: 'Preparando download', type: 'success' })
    await downloadit({ url: !eventHash ? `${apiUrl}/report-list-confirmated/${projectHash}?type=${type}` : `${apiUrl}/report-list-confirmated/${projectHash}/${eventHash}?type=${type}`, type });
    setLoading(false);
  }


  const getReportTellMe = async () => {
    setLoading(true);
    const response = await api.get(!eventHash ? `${apiUrl}/report-list-confirmated/${projectHash}` : `${apiUrl}/report-list-confirmated/${projectHash}/${eventHash}`);

    const data = response.data;

    const search: Array<Record<string, any>> = [];

    Object.keys(data?.searchable)?.map((key) => {
      if (!data?.searchable?.[key]?.ignore) {
        search.push(data?.searchable?.[key]);
      }
    })

    const groupParticipantes = getGroup({ prefix: 'Participantes - ', data: data.rows, search: search, where: { participou: 'Sim' } });
    const groupInscritos = getGroup({ prefix: 'Inscritos - ', data: data.rows, search: search });

    const groupParticipantesMedicos = getGroup({ prefix: 'Médicos - Participantes - ', data: data.rows, search: search, where: { participou: 'Sim', position: 'Médico' } });
    const groupInscritosMedicos = getGroup({ prefix: 'Médicos - Inscritos - ', data: data.rows, search: search, where: { position: 'Médico' } });


    setReport([...groupParticipantes, ...groupInscritos, ...groupParticipantesMedicos, ...groupInscritosMedicos]);


    setLoading(false);

  }


  const getReportTellMeBlock = async () => {
    setLoading(true);
    const response = await api.get(!eventHash ? `${apiUrl}/report-list-block/${projectHash}` : `${apiUrl}/report-list-block/${projectHash}/${eventHash}`);

    const data = response.data;

    setBlockDetail([...data]);


    setLoading(false);

  }

  const getReportTellMeProduct = async () => {
    setLoading(true);

    const products = await api.get(!eventHash ? `${apiUrl}/products-manager` : `${apiUrl}/products`,
      {
        params: {
          where: {
            project_id: projectHash,

          },
          limitItems: 10000,
          type: 'full'

        },
      });


    const response = await api.get(!eventHash ? `${apiUrl}/sales` : `${apiUrl}/sales`,
      {
        params: {
          where: {
            project_id: projectHash,

          },
          limitItems: 10000,
          type: 'full'

        }
      }
    );

    const data = response.data;
    let reports: Array<IResume> = [];
    const search: Array<Record<string, any>> = [
      { table: "Student", ref: "product_id", label: "Produto", search: true, },
      { table: "Student", ref: "company", label: "Empresa", search: true, },
      { table: "Student", ref: "category_id", label: "Especialidade", search: true },
      { table: "Student", ref: "position", label: "Categoria", search: true },
      { table: "Student", ref: "country", label: "Pais", search: true },
    ];





    products?.data?.rows?.map(product => {

      const group = getGroup({ prefix: `${product.title} - Inscritos - `, data: data.rows, search: search, where: { product_id: product._id.toString() } });
      const groupInscritosMedicos = getGroup({ prefix: `${product.title} -  Médicos - Inscritos - `, data: data.rows, search: search, where: { product_id: product._id.toString(), position: 'Médico' } });

      reports = [...reports, ...group, ...groupInscritosMedicos]

    })





    setReport(reports);


    setLoading(false);

  }



  useEffect(() => {

    if (showReport === 2) {
      getReportTellMe();
    }
    if (showReport === 3) {
      getReportTellMeBlock();
    }
    if (showReport === 4) {
      getReportTellMeProduct();
    }

  }, [showReport])


  const getUpdateReport = async () => {
    setLoading(true);

    await api.get(`${apiUrl}/update-report/${projectHash}/${eventHash}`);
    setLoading(false);
  }

  const [read, setRead] = useState('');

  const subscriptionTypes = {
    'subscription': { text: 'Inscrição', label: 'Inscrição', value: 'subscription' },
    'exam': { text: 'Aguardando resultado da prova', label: 'Aguardando resultado da prova', value: 'exam' },
    'curriculum': { text: 'Envio de currículo', label: 'Envio de currículo', value: 'curriculum' },
    'curriculum_waiting': { text: 'Avaliação de currículo', label: 'Avaliação de currículo', value: 'curriculum_waiting' },
    'registration': { text: 'Pré-matricula', label: 'Pré-matricula', value: 'registration' },
    'confirmation': { text: 'Confirmação de matrícula', label: 'Confirmação de matrícula', value: 'confirmation' },
  }

  const [positionValue, setPositionValue] = useState(1);
  const { configuration } = useConfiguration();

  const callTransfer = async (url) => {
    try {
      await api.get(url)
      addToast({ title: 'Saque realizado com sucesso', type: 'success' })

    }
    catch (err) {
      addToast({ title: 'Erro ao sacar', type: 'error' })
    }
  }
  return (
    <Container>
      {eventHash ? <button style={{ margin: '10px auto' }} onClick={() => getUpdateReport()} className='defaultButton'>Atualizar relatórios</button> : <></>}






      {/*
      <Form onSubmit={reportProject} style={{padding: '20px', margin: '20px auto'}}>

        <DatePicker name={'date'} placeholder="Data"/>
        <button className='defaultButton'>Gerar Relatório</button>

      </Form>

  */}

      {loading ? <Loader message='Carregando... Esta solicitação pode demorar um pouco' /> :
        <>
          <h2>{project.title}</h2>

          {/*  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', width: '100%' }}>

            <button style={{ margin: '10px', padding: '10px' }} onClick={() => setShowReport(1)} className={showReport === 1 ? 'defaultButtonReverse' : 'defaultButton'} >Geral</button>
            <button style={{ margin: '10px', padding: '10px' }} onClick={() => setShowReport(2)} className={showReport === 2 ? 'defaultButtonReverse' : 'defaultButton'}>Detalhado</button>
            <button style={{ margin: '10px', padding: '10px' }} onClick={() => setShowReport(3)} className={showReport === 3 ? 'defaultButtonReverse' : 'defaultButton'}>Por bloco</button>
            <button style={{ margin: '10px', padding: '10px' }} onClick={() => setShowReport(4)} className={showReport === 4 ? 'defaultButtonReverse' : 'defaultButton'}>Por produto / inscrição</button>


</div> */}
          {showReport === 2 ?
            <>

              <ReadResumeList data={report} />



            </>
            : <></>}

          {showReport === 4 ?
            <>

              <ReadResumeList data={report} />



            </>
            : <></>}
          {showReport === 1
            ? <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', width: '100%', gap: '10px' }}>
                <button style={{ background: positionValue === 1 ? 'rgb(12,148,144)' : 'rgb(0,15,64)', padding: '15px' }} onClick={() => setPositionValue(1)} className='defaultButtonMini'>Inscrições</button>
                <button style={{ background: positionValue === 6 ? 'rgb(12,148,144)' : 'rgb(0,15,64)', padding: '15px' }} onClick={() => setPositionValue(6)} className='defaultButtonMini'>Gráficos</button>

                <button style={{ background: positionValue === 2 ? 'rgb(12,148,144)' : 'rgb(0,15,64)', padding: '15px' }} onClick={() => setPositionValue(2)} className='defaultButtonMini'>Dados gerais</button>
                <button style={{ background: positionValue === 3 ? 'rgb(12,148,144)' : 'rgb(0,15,64)', padding: '15px' }} onClick={() => setPositionValue(3)} className='defaultButtonMini'>Análise Currícular</button>
                <button style={{ background: positionValue === 4 ? 'rgb(12,148,144)' : 'rgb(0,15,64)', padding: '15px' }} onClick={() => setPositionValue(4)} className='defaultButtonMini'>Matrículas</button>
                <button style={{ background: positionValue === 5 ? 'rgb(12,148,144)' : 'rgb(0,15,64)', padding: '15px' }} onClick={() => setPositionValue(5)} className='defaultButtonMini'>Downloads</button>
              </div>

              {positionValue === 1 && <>
                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    <tr >
                      <td >Inscrições</td>
                      <td>Pagas: {project.sales_paid || '-'} </td>
                      <td>Aguardando: {project.sales_waiting || '-'} </td>
                    </tr>
                  </tbody></table>

                <h2>Inscrições</h2>

                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>

                    {readListFields(project?.product_list || [])}




                  </tbody>
                </table>

              </>}
              {positionValue === 2 && <>
                <h2>Dados do Projeto</h2>

                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    <tr><td>Título: </td><td>{project.title}</td></tr>
                    <tr><td>Etapa atual</td><td>{subscriptionTypes?.[project.stage]?.label || 'Não identificado'}</td></tr>

                    <tr><td>Permitir Atendimento especial</td><td>{project.allow_special_treatment === 'yes' ? 'Sim' : project.allow_special_treatment === 'open' ? 'Somente visualização' : 'Não'}</td></tr>
                    <tr><td>Permitir Recurso de Inscrição</td><td>{project.allow_subscription_recurse === 'yes' ? 'Sim' : project.allow_subscription_recurse === 'open' ? 'Somente visualização' : 'Não'}</td></tr>
                    <tr><td>Permitir Recurso de Prova</td><td>{project.allow_proof_recurse === 'yes' ? 'Sim' : project.allow_proof_recurse === 'open' ? 'Somente visualização' : 'Não'}</td></tr>

                    <tr><td>Autorizar avaliação currícular</td><td>{project.allow_curriculum_avaliation === 'yes' ? 'Sim' : project.allow_curriculum_avaliation === 'open' ? 'Somente visualização' : 'Não'}</td></tr>
                    <tr><td>Autorizar etapa de recursos de avaliação currícular</td><td>{project.allow_curriculum_recurse === 'yes' ? 'Sim' : project.allow_curriculum_recurse === 'opens' ? 'Somente visualização' : 'Não'}</td></tr>
                    <tr><td>Autorizar visualização das avaliações pelos participantes</td><td>{project.allow_curriculum_avaliation_show === 'yes' ? 'Sim' : project.allow_curriculum_avaliation_show === 'open' ? 'Somente visualização' : 'Não'}</td></tr>
                    <tr><td>Mostrar retorno da etapa de recursos</td><td>{project.allow_curriculum_recurse_show === 'yes' ? 'Sim' : project.allow_curriculum_recurse_show === 'open' ? 'Somente visualização' : 'Não'}</td></tr>
                    <tr><td>Permitir Cancelamento de Matrícula</td><td>{project.allow_cancel === 'yes' ? 'Sim' : project.allow_cancel === 'open' ? 'Somente visualização' : 'Não'}</td></tr>
                    <tr><td>Permitir Reserva Militar</td><td>{project.allow_reserve === 'yes' ? 'Sim' : project.allow_reserve === 'open' ? 'Somente visualização' : 'Não'}</td></tr>

                  </tbody>
                </table>

                <h2>Opções de pagamento</h2>

                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    <tr><td>Boleto</td><td>{project.allow_boleto === 'no' ? 'Não' : 'Sim'}</td></tr>
                    <tr><td>Pix</td><td>{project.allow_pix === 'no' ? 'Não' : 'Sim'}</td></tr>
                    <tr><td>Cartão de Crédito</td><td>{project.allow_credit_card === 'no' ? 'Não' : 'Sim'}</td></tr>
                    <tr><td>Liberar cortesias</td><td>{project.allow_cortesy === 'no' ? 'Não' : 'Sim'}</td></tr>

                  </tbody>
                </table>

              </>}

              {positionValue === 3 && <>
                <h2>Regras de Avaliação currícular</h2>

                <h2>Acesso direto</h2>
                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    {project?.curriculum_configuration?.map(conf => {

                      return <>
                        <tr className='highlight'><th colSpan={2}>{conf.title}</th><th> Pontuação máxima: {conf.points} </th></tr>


                        {conf.categories?.map(curriculumPointsCategory => {

                          if (curriculumPointsCategory?.subcategories?.length > 0) {
                            return <><tr>  <td colSpan={2}>{curriculumPointsCategory?.title} </td><td>Nota máxima nesta categoria: {curriculumPointsCategory.points}</td></tr>

                              {curriculumPointsCategory?.subcategories?.map(curriculumPointsSubcategory => {

                                return <tr style={{ background: '#eee', }}><td style={{ maxWidth: '30px', width: '30px' }}><FaChevronRight size={15} /></td><td>{curriculumPointsSubcategory?.title}</td><td>Peso: {curriculumPointsSubcategory.value}</td></tr>

                              })}
                            </>

                          }
                          else {

                            return <tr>  <td colSpan={2}>{curriculumPointsCategory?.title} </td><td>Peso: {curriculumPointsCategory.value}</td></tr>


                          }



                        })}





                      </>

                    })}
                  </tbody></table>




                <h2>Pré-requisitos</h2>


                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    {project?.curriculum_configuration_pre_requisitos?.map(conf => {

                      return <>
                        <tr className='highlight'><th colSpan={2}>{conf.title}</th><th> Pontuação máxima: {conf.points} </th></tr>


                        {conf.categories?.map((curriculumPointsCategory, index) => {

                          if (curriculumPointsCategory?.subcategories?.length > 0) {
                            return <><tr>  <td colSpan={2}  >{`${index + 1}) `}{curriculumPointsCategory?.title} </td><td>Nota máxima nesta categoria: {curriculumPointsCategory.points}</td></tr>

                              {curriculumPointsCategory?.subcategories?.map(curriculumPointsSubcategory => {

                                return <tr style={{ background: '#eee', }}><td style={{ maxWidth: '30px', width: '30px' }}><FaChevronRight size={15} /></td><td>{curriculumPointsSubcategory?.title}</td><td>Peso: {curriculumPointsSubcategory.value}</td></tr>

                              })}
                            </>

                          }
                          else {

                            return <tr>   <td colSpan={2} >{`${index + 1}) `}{curriculumPointsCategory?.title} </td><td>Peso: {curriculumPointsCategory.value}</td></tr>


                          }



                        })}





                      </>

                    })}
                  </tbody></table>
              </>}

              {positionValue === 4 && <>


                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    <tr className='highlight'><th>ABAS DE MATRÍCULA</th></tr>
                    {project?.register_steps?.map((doc, index) => {
                      return <tr><td>{`${index + 1}) `}{doc.title}</td></tr>
                    })}
                  </tbody></table>

                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    <tr className='highlight'><th> ACESSO DIRETO - Documentos solicitados </th></tr>
                    {project?.documents_configuration?.map((doc, index) => {
                      return <tr><td>{`${index + 1}) `}{doc.title}</td></tr>
                    })}
                  </tbody></table>


                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    <tr className='highlight'><th> PRÉ-REQUISITOS - Documentos solicitados</th></tr>
                    {project?.documents_configuration_pre_requisitos?.map((doc, index) => {
                      return <tr><td>{`${index + 1}) `}{doc.title}</td></tr>
                    })}
                  </tbody></table>


                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    <tr className='highlight'><th colSpan={2}>DADOS PESSOAIS</th></tr>
                    {project?.subscription_configuration?.map((doc, index) => {
                      return <> <tr><td colSpan={2}>{`${index + 1}) `} {doc.title}</td></tr>
                        {doc?.options?.length > 0 && doc?.options?.map(option => {
                          return <tr style={{ background: '#eee', }}><td style={{ maxWidth: '30px', width: '30px' }}><FaChevronRight size={15} /></td><td  >{option.label}</td></tr>
                        })}
                      </>
                    })}
                  </tbody></table>


                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    <tr className='highlight'><th colSpan={2}>COMPROVANTES DE VACINAS</th></tr>
                    {project?.vaccine_configuration?.map((doc, index) => {
                      return <> <tr><td colSpan={2}>{`${index + 1}) `} {doc.title}</td></tr>
                        {doc?.options?.length > 0 && doc?.options?.map(option => {
                          return <tr style={{ background: '#eee', }}><td style={{ maxWidth: '30px', width: '30px' }}><FaChevronRight size={15} /></td><td  >{option.label}</td></tr>
                        })}
                      </>
                    })}
                  </tbody></table>

              </>}


              {positionValue === 5 && <>

                <h2>Exportar</h2>

                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>

                    <tr><td>INSCRIÇÕES - GERAL</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/report-sales/${project.url}`, params: { where: { project_id: project?.url, payment_status_id: 'paid' } } })} /></td></tr>
                    <tr><td>INSCRIÇÕES - PAGAS</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/report-sales-paid/${project.url}`, params: { where: { project_id: project?.url, payment_status_id: 'paid' } } })} /></td></tr>
                    <tr><td>Solicitações de atendimento especial</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/selective-process-special-treatment`, params: { where: { project_id: project?.url } } })} /></td></tr>
                    <tr><td>Recursos de inscrição</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/selective-process-subscription-recurse`, params: { where: { project_id: project?.url } } })} /></td></tr>
                    <tr><td>Recursos de prova</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/selective-process-proof-recurse`, params: { where: { project_id: project?.url } } })} /></td></tr>

                    <tr><td>ACESSO DIRETO - Análise Currícular</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/selection_read_avaliation/${project.url}/acesso_direto` })} /></td></tr>
                    <tr><td>PRÉ-REQUISITOS - Análise Currícular</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/selection_read_avaliation/${project.url}/pre-requisitos` })} /></td></tr>
                    <tr><td>ACESSO DIRETO - Matrículas</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/get-report-final/${project.url}/all/acesso_direto` })} /></td></tr>
                    <tr><td>PRÉ-REQUISITOS - Matrículas</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/get-report-final/${project.url}/all/pre-requisitos` })} /></td></tr>
                    <tr><td>ACESSO DIRETO - Aprovados</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/get-report-final/${project.url}/aprovado/acesso_direto` })} /></td></tr>
                    <tr><td>PRÉ-REQUISITOS - Aprovados</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/get-report-final/${project.url}/aprovado/pre-requisitos` })} /></td></tr>

                  </tbody>
                </table>

                <h2>Financeiro</h2>

                <table className='tableRef' style={{ width: '100%' }}>
                  <tbody>
                    <tr><td>Relatório Financeiro em PDF</td><td><FinanceReportByProjectModule lineData={configuration} /></td></tr>

                    <tr><td>Conciliação Financeira</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/check-saldo/${project.url}`, params: { where: { project_id: project?.url, payment_status_id: 'paid' } } })} /></td></tr>
                    <tr><td>Relatório de Saques</td><td><FaFileExcel style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => downloadit({ url: `/check-saldo/${project.url}/saque`, params: { where: { project_id: project?.url, payment_status_id: 'paid' } } })} /></td></tr>
                    <tr><td>Solicitar saque</td><td><FaMoneyBill style={{ width: '30px', fontSize: '24px', minWidth: '30px', color: 'rgb(12,148,144)', cursor: 'pointer' }} onClick={() => callTransfer(`/make-marketplace-transfer/${project?._id}`)} /></td></tr>

                  </tbody>
                </table></>}
            </> : <> </>}

          {positionValue === 6 ? <> <br /> <Table table={'Sale'} project_id={project?.url} component_id="sales" endpoint="sales" orderBy="createdAt" orderDirection="asc"
            resources={
              {
                search: 'yes',
                pagination: 'yes',
                head_modules: 'yes',
                filters: 'yes',
              }
            }

            header={[
              { title: 'Produto', column: 'product_id', includeColumn: 'title', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
              { title: 'Cliente', column: 'client_id', includeColumn: 'name', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
              { title: 'Forma de Pagamento', column: 'payment_method_id', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
              { title: 'Àrea + Subarea', column: 'area-subarea', insert: 'yes', update: 'yes', search: 'no', export: 'yes' },
              { title: 'Categoria', column: 'category', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
              { title: 'Área', column: 'area', insert: 'yes', update: 'yes', search: 'no', export: 'yes' },
              { title: 'Subárea', column: 'subarea', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },
              { title: 'Campos adicionais', column: 'fields_text', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },

              { title: 'Status de Pag', column: 'payment_status_id', insert: 'yes', update: 'yes', search: 'yes', export: 'yes' },

              { title: 'Parcelamento', column: 'installment', insert: 'yes', update: 'yes', search: 'no', export: 'yes' },
            ]} /> </> : <></>}

          {showReport === 3 ? <>

            <table style={{ width: '100%' }}>
              {blockDetail.map(d => {
                return <> <tr className='list-template'>
                  <td className='list-template-date' style={{ background: '#333', color: '#fff', width: '100%' }} colSpan={2}>{dateSimple(d.release_date)} - {d.title}</td>

                </tr>
                  <tr className='list-template'>
                    <td className='list-template-date'>Live</td>
                    <td className='list-template-quantity'>{d.live}</td>
                  </tr>
                  <tr className='list-template'>
                    <td className='list-template-date'>On Demand</td>
                    <td className='list-template-quantity'>{d.onDemand}</td>
                  </tr>
                  <tr className='list-template'>
                    <td className='list-template-date'>Total</td>
                    <td className='list-template-quantity'>{d.total}</td>
                  </tr>
                  <tr className='list-template'>
                    <td className='list-template-date'>Mensagens no chat</td>
                    <td className='list-template-quantity'>{d.chat}</td>
                  </tr>
                </>
              })}

            </table>;

          </> : <></>}</>}

    </Container>
  );
};

export default Resumo;

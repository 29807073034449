import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';
import { date, simpleDate } from '../../../../utils/date';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import MedicalRegistration from '../../../DashboardPages/features/MedicalRegistration';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;

}

const Registration: React.FC<IProps> = ({ changeStatus }) => {


  const [profile, setProfile] = useState<Record<string, any>>({})

  const { projectHash, configuration } = useConfiguration();




  const load = async () => {



    const response = await api.get(`/my-medic-subscription/${projectHash}`);

    if (response?.data) {
      setProfile(response.data);
    }

  }

  useEffect(() => {

    if (projectHash) {

      load();
    }

  }, [projectHash])

  if (profile?.event_profile?.[projectHash]?.allow_registration === 'no') {
    return <Container >

      <div className='user-block shadow' style={{ overflow: 'hidden' }}>
        <div className='content-block' style={{ padding: '10px' }}>

          <p className='contentTitle'>Pré-matrícula não autorizada.</p>

        </div>
      </div>
    </Container>
  }

  /*  if(configuration.stage !== 'registration'){
     return  <Container >
 
 <div className='user-block shadow' style={{overflow:'hidden'}}>
           <div className='content-block' style={{padding: '10px'}}>
            
                 <p className='contentTitle'>A Etapa de matrícula não foi iniciada.</p>
 
                 </div>
     </div>
     </Container>
   } */

  if (!profile?.client_id_response) {
    return <Container >   <div className='user-block shadow' style={{ overflow: 'hidden' }}>
      <div className='content-block' style={{ padding: '10px' }}>
        <nav style={{ background: '#333', padding: '30px', margin: '20px auto', color: '#fff', borderRadius: '15px' }}>Carregando ...</nav>

      </div></div>
    </Container>
  }

  if (profile?.client_id_response && profile.status !== 'register') {
    return <Container >   <div className='user-block shadow' style={{ overflow: 'hidden' }}>
      <div className='content-block' style={{ padding: '10px' }}>
        <nav style={{ background: 'rgb(0,200,0)', padding: '30px', margin: '20px auto', color: '#fff', borderRadius: '15px' }}>Envio Confirmado</nav>

      </div></div>
    </Container>
  }


  if (profile?.register_position !== 'Classificado') {
    return <Container >   <div className='user-block shadow' style={{ overflow: 'hidden' }}>
      <div className='content-block' style={{ padding: '10px' }}>
        <nav style={{ background: '#ddd', padding: '30px', margin: '20px auto', color: '#333', borderRadius: '15px' }}>Sua matrícula não está liberada<br />

          <strong>Seu status: {profile?.register_position}</strong>
        </nav>

      </div></div>
    </Container>
  }


  return (
    <>
      <Container >

        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <MedicalRegistration changeStatus={changeStatus} />
          </div>
        </div>
      </Container>
    </>);



};
export default Registration;

import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBarcode, FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useParams } from 'react-router-dom';
import { isArray } from 'util';
import { urlWebsite } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useModal } from '../../../../hooks/Modal';
import api from '../../../../services/api';
import { dateFullWithHour } from '../../../../utils/date';
import format from '../../../../utils/format';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import Sale from '../../../DashboardPages/pages/Sales';
import PaymentRegistration from './modules/PaymentRegistration';

import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;
  myMedicSubscription?: Record<string, any>;
}

const Pagamentos: React.FC<IProps> = ({ myMedicSubscription, changeStatus }) => {

  const { user } = useAuth();
  const { addModal, removeModal } = useModal();
  const [tickets, setTickets] = useState<Array<Record<string, any>>>([])
  const [invoices, setInvoices] = useState<Array<Record<string, any>>>([])
  const [products, setProducts] = useState<Array<Record<string, any>>>([])
  const { configuration } = useConfiguration();
  const { projectHash } = useParams<Record<string, any>>();
  const getPaymentStatus = {

    waiting: "Aguardando Pagamento",
    paid: "Pago",
    cancelled: "Cancelado",
    refunded: "Estornado"

  }

  const getPaymentMethod = {

    boleto: "Boleto",
    boleto_recurring: "Boleto",
    credit_card: "Cartão de Crédito",
    credit_card_recurring: "Cartão de Crédito",
    pix: "Pix",
    deposit: "Depósito",
    cortesy: "Cortesia",

  }



  const loadProducts = async () => {



    const response = await api.get(`/products-list/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      setProducts(response.data.rows);
    }

  }

  const load = async () => {



    const response = await api.get(`/my-tickets/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      setTickets(response.data.rows);
    }

  }

  const loadInvoices = async () => {



    const response = await api.get(`/my-invoices/${projectHash}`);

    if (response?.data?.rows?.length > 0) {
      response?.data?.rows.sort((a, b) => a?.payment_date < b?.payment_date ? -1 : a?.payment_date > b?.payment_date ? 1 : 0);
      setInvoices(response.data.rows);
    }

  }

  const printFields = (productList, item) => {

    const list: Array<JSX.Element> = [];
    const product = productList?.find(i => i?._id?.toString() === item?.product_id?.toString());



    if (item?.fields) {

      Object?.keys(item?.fields)?.map(key => {

        const fieldName = product?.fields?.find(p => p?.field === key);
        const content: JSX.Element[] = [];



        if (fieldName) {
          if (fieldName?.title === 'Requerer a pontuação adicional conforme item 3.2 do edital?') {
            if (!item?.fields?.[key]) {
              item.fields[key] = 'Não';
            }
          }

          if (item?.fields?.[key]) {

            if (typeof item?.fields?.[key] === 'object') {

              item?.fields?.[key]?.map(i => {
                console.log(i)
                if (i?.indexOf('event/') >= 0) {
                  content.push(<a href={`${urlWebsite}/${i}`} download><button style={{ margin: '5px 0px' }} className='defaultButtonMini'>Documento anexado</button></a>);
                }
                else {
                  content.push(<p style={{ color: '#333' }}>{i}</p>);

                }
              })

            }
            else {
              if (item?.fields?.[key]?.indexOf('event/') >= 0) {
                content.push(<a href={`${urlWebsite}/${item?.fields?.[key]}`} download><button className='defaultButtonMini' style={{ margin: '5px 0px' }}>Documento anexado</button></a>);
              }
              else {
                content?.push(<p style={{ color: '#333' }}>{item?.fields?.[key]}</p>)

              }

            }

          }

          if (content?.length > 0) {
            list.push(<p><strong style={{ color: '#333', marginBottom: '5px', display: 'block' }}>{fieldName?.title}:</strong>  {content}<br /></p>)
          }
        }



      })



    }

    return list;
  }

  const reloadAll = async () => {

    loadInvoices();
    load();
    loadProducts();
    removeModal('payment')


  }

  useEffect(() => {

    if (projectHash) {
      loadInvoices();
      load();
      loadProducts();
    }

  }, [projectHash])

  const openPayment = (project) => {

    addModal({ title: '', key: 'payment', theme: 'whiteModalMini', content: <PaymentRegistration reloadAll={reloadAll} project={{ url: projectHash }} /> })
  }

  return (
    <>
      <Container >
        {myMedicSubscription?.status === 'aprovado' && configuration?.registration_product ? <button className='defaultButton' style={{ alignSelf: 'flex-start' }} onClick={() => openPayment(configuration)}>Efetuar pagamento</button> : <></>}
        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Meus pagamentos</p>
            <table><tbody>
              {tickets?.length > 0 && <>    <tr><th>Data</th> <th>Pagamento</th></tr>
                {tickets.map(sale => {

                  return <tr key={sale._id}><td style={{ fontSize: '14px', lineHeight: '16px' }}>{dateFullWithHour(sale?.createdAt)}</td> <td style={{ fontSize: '14px', lineHeight: '16px' }}>
                    <strong>{sale?.product_id_response?.title}</strong><br />
                    <p style={{ margin: '5px 0px' }}>Protocolo de inscrição: {sale?.sale_hash}</p>
                    {sale?.category ? <p>Categoria : {sale?.category}</p> : <></>}
                    {sale?.area ? <p>Área : {sale?.area}</p> : <></>}
                    {sale?.subarea ? <p>Subárea : {sale?.subarea}</p> : <></>}

                    {printFields(products, sale)}
                    <br /> <br />Pagador: {sale?.client_id_response?.name}
                    <br /> Valor: {format(sale.total)}
                    <br />Forma de pagamento: {getPaymentMethod?.[sale?.payment_method_id] || 'Não identificado'}
                    <br />Status de pagamento: {getPaymentStatus?.[sale?.payment_status_id] || 'Erro ao identificar'}
                    {sale?.boleto_url ? <><br /><a href={sale?.boleto_url}><strong>Clique aqui para visualizar o boleto </strong></a></> : <></>}
                    {sale?.payment_method_id === 'boleto' && <><br />
                      <a href={`/app/${projectHash}/checkout/${sale?.product_id_response?.hash_link}`}><button style={{ border: '1px solid #ddd', color: '#fff', background: '#333', borderRadius: '15px', padding: '10px', cursor: 'pointer', margin: '10px 0px' }}>
                        Emitir nova cobrança </button></a></>}


                    {sale?.payment_status_id === 'paid' && sale?.total > 0 && <><br />
                      <a href={`/app/${projectHash}/recibo/${sale?.sale_hash}`}><button style={{ border: '1px solid #ddd', color: '#fff', background: '#333', borderRadius: '15px', padding: '10px', cursor: 'pointer', margin: '10px 0px' }}>
                        Visualizar Recibo </button></a></>}


                    {sale?.payment_status_id === 'paid' && projectHash === 'autoavaliacao-hcpa-2022' && <><br />
                      <a href={`/app/${projectHash}/formulario/${sale?.sale_hash}`}><button style={{ border: '1px solid #ddd', color: '#fff', background: '#333', borderRadius: '15px', padding: '10px', cursor: 'pointer', margin: '10px 0px' }}>
                        Visualizar formulário para impressão </button></a></>}


                  </td></tr>
                })}
              </>}

              {tickets?.length < 1 &&
                <tr  ><td>Nenhum pagamento identificado</td></tr>}
            </tbody>
            </table>
          </div>

        </div>


        <div className='user-block shadow' style={{ overflow: 'hidden' }}>
          <div className='content-block' style={{ padding: '10px' }}>

            <p className='contentTitle'>Faturas</p>
            <table><tbody>
              {invoices?.length > 0 && <>    <tr> <th>Pagamento</th></tr>
                {invoices.map(sale => {

                  return <tr key={sale._id}>


                    <td style={{ fontSize: '14px', lineHeight: '16px' }}>

                      <strong style={{ margin: '5px 0px', color: '#333' }}>Parcela:{` `}
                        {sale?.installment_number}
                      </strong>
                      <p style={{ margin: '5px 0px' }}>Data de Vencimento:{` `}
                        {dateFullWithHour(sale?.payment_date
                        )}</p>


                      <strong>{sale?.product_id_response?.title}</strong><br />
                      <p style={{ margin: '5px 0px' }}>Protocolo de inscrição: {sale?.sale_hash}</p>
                      {sale?.category ? <p>Categoria : {sale?.category}</p> : <></>}
                      {sale?.area ? <p>Área : {sale?.area}</p> : <></>}
                      {sale?.subarea ? <p>Subárea : {sale?.subarea}</p> : <></>}


                      <p style={{ margin: '5px 0px' }}>Pagador: {sale?.client_id_response?.name}</p>
                      <p style={{ margin: '5px 0px' }}> Valor: {format(sale.installment_value)}</p>
                      <p style={{ margin: '5px 0px' }}>Forma de pagamento: {getPaymentMethod?.[sale?.payment_method_id] || 'Não identificado'}</p>
                      <p style={{ margin: '5px 0px' }}>Status de pagamento: {getPaymentStatus?.[sale?.payment_status_id] || 'Erro ao identificar'}</p>
                      <p style={{ margin: '5px 0px' }}>
                        {
                          sale?.boleto_url ? <a href={sale?.boleto_url} target="_BLANK"> <button className='defaultButtonMini'>Visualizar boleto</button> </a> : <></>

                        }</p>







                    </td></tr>
                })}
              </>}

              {tickets?.length < 1 &&
                <tr  ><td>Nenhum pagamento identificado</td></tr>}
            </tbody>
            </table>
          </div>

        </div>
      </Container>
    </>
  );
};
export default Pagamentos;

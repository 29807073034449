import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import Loader from '../../../../components/Loader';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';
import { date, simpleDate } from '../../../../utils/date';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import UploadCurriculumContent from '../../../DashboardPages/features/UploadCurriculumContent';
import VisualizateCurriculum from './modules/visualizateCurriculum';
import { Container, ContainerWhite } from './styles';

interface IProps {
  changeStatus: Function;

}

const Curriculum: React.FC<IProps> = ({ changeStatus }) => {


  const [profile, setProfile] = useState<Record<string, any>>({})

  const { projectHash, configuration } = useConfiguration();



  const load = async () => {



    const response = await api.get(`/my-medic-subscription/${projectHash}`);

    if (response?.data) {
      setProfile(response.data);
    }

  }

  useEffect(() => {

    if (projectHash) {

      load();
    }

  }, [projectHash])

  if (!profile?._id) {
    return <Loader message='Carregando ...' />
  }

  if (!profile?.client_id_response) {
    return <Container >   <div className='user-block shadow' style={{ overflow: 'hidden' }}>
      <div className='content-block' style={{ padding: '10px' }}>
        <nav style={{ background: '#333', padding: '30px', margin: '20px auto', color: '#fff', borderRadius: '15px' }}>Carregando ...</nav>

      </div></div>
    </Container>
  }



  return (
    <>
      <Container >

        {configuration?.allow_curriculum_avaliation_show === 'yes' ? <VisualizateCurriculum lineData={profile} /> : <></>}

        {configuration?.allow_curriculum_avaliation_show !== 'yes' ? <UploadCurriculumContent profile={profile} key={`upload-${projectHash}`} template={profile.category?.toUpperCase() === 'PRÉ-REQUISITOS' ? configuration?.curriculum_configuration_pre_requisitos || [] : configuration?.curriculum_configuration || []} currentValue={profile?.curriculum || {}} /> : <></>}

      </Container>
    </>
  );
};
export default Curriculum;
